@import '../../../../../../styles/variables';

.block {
  margin-bottom: 48px;

  @include min768 {
    margin-bottom: 64px;
  }
  @include min1280 {
    margin-bottom: 80px;
  }
}


.primitive {
  table {
    border: 1px solid $orange;
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 800px;
  }

  th {
    background: $orange10;
  }

  th,
  td {
    padding: 15px 10px 15px 10px;
    border-bottom: 1px solid $orange;
    width: 25%;

    &:not(:last-of-type) {
      border-right: 1px solid $orange;
      border-left: 1px solid $orange;
    }
  }

  ul, ol {
    margin-bottom: 0;
  }

  ol li + li,
  ul li + li {
    margin-top: 12px;
  }

  ul li {
    padding-left: 16px;

    @include min1280 {
      padding-left: 24px;
    }


    &::before {
      top: 9px;
    }
  }
}

.table-wrap {
  overflow: auto;
  max-width: 100%;
}
