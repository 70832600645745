@import 'STYLES/variables';

.main {
  margin-top: 80px;
  overflow: hidden;

  @include min768 {
    margin-top: 120px;
  }
  @include min1280 {
    margin-top: 220px;
    padding-bottom: 200px;
  }
  @include min1600 {
    margin-top: 230px;
  }
}

.title {
  text-align: center;
  color: $orange;
  margin-bottom: 32px;

  @include min768 {
    margin-bottom: 48px;
  }
  @include min1280 {
    margin-bottom: 80px;
  
    @include maxHeight {
      margin-bottom: 56px;
    }
  }
}

.slider {
  position: relative;
  
  --prev: 100px;
  --next: -100px;
  
  :global(.swiper) {
    overflow: visible !important;
  }

  :global(.swiper-vertical) {
    height: 640px;

    @include maxHeight {
      height: 520px;
    }
  }

  :global(.swiper-slide) {
    background: $white;
    border-radius: 64px;
    overflow: hidden;
    padding: 40px 20px 56px;
    box-sizing: border-box;

    @include min768 {
      border-radius: 104px;
      padding: 56px var(--col) 72px;
      display: flex;
      //align-items: center;
      justify-content: center;
      min-height: 370px;
      height: auto;
  
      @media (max-height: 600px) {
        padding-top: 32px;
        padding-bottom: 56px;
        min-height: 370px * 0.75;
      }
    }
    @include min1280 {
      padding: 128px var(--col);
      border-radius: 200px;
      min-height: 1px;
      height: 100%;
      
      @include maxHeight {
        padding: 96px var(--col);
      }

      & > * {
        opacity: 0;
      }
    }
  }


  :global(.swiper-slide-next),
  :global(.swiper-slide-next) + :global(.swiper-slide),
  :global(.swiper-slide-active),
  :global(.swiper-slide-visible),
  :global(.swiper-slide) + :global(.swiper-slide-prev) {
    & > * {
      opacity: 1;
    }
  }

  :global(.swiper-slide),
  :global(.swiper-slide-prev),
  :global(.swiper-slide-active) {
    z-index: 3 !important;
    position: relative;
  }


  :global(.swiper-slide) + :global(.swiper-slide-prev) {
    // opacity: 1!important;
  }


  :global(.swiper-slide-next) + :global(.swiper-slide),
  :global(.swiper-slide-next) {
    z-index: 8 !important;
    position: relative;
  }

  :global(.swiper-pagination) {
    display: flex;
    justify-content: center;
    margin-top: 26px;
    gap: 8px;

    @include min1280 {
      margin: 0;
      flex-direction: column;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      z-index: 7;
    }

    :global(.swiper-pagination-bullet) {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: transparent;
      position: relative;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        position: absolute;
      }

      &::before {
        width: 11px;
        height: 11px;
        border: 1px solid $orange;
      }

      &::after {
        width: 12px;
        height: 12px;
        background: $orange;
        opacity: 0;
        transition: opacity 0.3s $ease;
      }
    }

    :global(.swiper-pagination-bullet-active) {
      &::after {
        opacity: 1;
      }
    }
  }
}

.nav {
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translate3d(-50%,0,0);
  display: flex;
  gap: 8px;
  flex-direction: row;
  z-index: 10;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s $ease;
  
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}


.next {
  svg {
    transform: rotate(180deg);
  }
}

.card {
  text-align: center;

  @include min1280 {
  }

  .image {
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    width: 72px;
    height: 72px;
    margin-bottom: 24px;

    @include min768 {
      width: 120px;
      height: 120px;
  
      @media (max-height: 600px) {
        width: 120px * 0.75;
        height: 120px * 0.75;
      }
    }
    @include min1280 {
      width: 136px;
      height: 136px;
      margin-bottom: 40px;
    }
  }

  &Title {
    color: $orange;
    margin-bottom: 8px;

    @include min768 {
      margin-bottom: 16px;
    }
  }
}

.wrap {
  position: relative;
}


.deskTitle {
  color: $orange;
  text-align: center;
  margin: 217px 0 80px;
  position: absolute;
  top: 0 !important;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);


  @include min1920 {
    height: 95%;
  }


  &Text {
    position: sticky;
    margin-bottom: clamp(800px, 20vw, 1200px);
    top: 20%;

    @include min1280 {
      top: clamp(0.5%, 0.7vw, 20%);
    }
    @include min1600 {
      top: clamp(10%, 7vw, 20%);
    }
  }
}


.anchors {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  left: calc(100% - 50px);
  z-index: 2;

  &Wrapper {
    &Item {
      width: 12px;
      height: 12px;
      margin-bottom: 10px;
      border: 1px solid $orange;
      border-radius: 50%;
      transition: all 0.5s ease;
      cursor: pointer;

      &.active {
        background: $orange;
      }
    }
  }
}


.deskSlider {
  position: relative;

  .test {
    position: absolute;
    width: 100%;
    top: 0 !important;
    z-index: 2;
    height: calc(100% - 50vh);

    @include min768 {
      height: calc(100% - 52vh);
    }
    @include min1920 {
      height: calc(100% - 45vh);
    }

    &Inner {
      position: sticky;
      top: 30%;
      padding: 41px 20px 48px;

      @include min768 {
        margin: 0;
        border-radius: 104px;
        padding: 57px 91px 74px;
      }
      @include min1280 {
        border-radius: 200px;
        padding: 128px 91px 186px;
      }

      img {
        min-width: 72px;
        min-height: 72px;
        margin-bottom: 25px;
        opacity: 0;
        visibility: hidden;

        @include min768 {
          min-width: 120px;
          min-height: 120px;
          margin-bottom: 48px;
        }
        @include min1280 {
          min-width: 136px;
          min-height: 136px;
          margin-bottom: 40px;
        }
      }

      &Title {
        color: $orange;
        margin-bottom: 8px;
        opacity: 0;
        visibility: hidden;

        @include min768 {
          margin-bottom: 16px;
        }
      }

      &Description {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .cardsWrapper {
    margin-top: 400px;
    padding-bottom: 200px;
  }

  // Позиция для стики карточек перка
  @for $i from 1 through 6 {
    :nth-child(#{$i}) {
      top: 32%;

      @include min1280 {
        top: calc(clamp(10%, 10vw, 20%) + 0px * $i);
      }
      @include min1600 {
        top: calc(clamp(15%, 14vw, 34%) + 0px * $i);
      }
    }
  }


  .card {
    position: sticky;
    margin-bottom: 30vh;
    opacity: 1;
    transform: none;
    transition: all 0.8s ease;


    &.disabled {
      transition: all 0.8s ease;
      opacity: 0.6;
      transform: scale(0.6) translateY(0);
      animation: disable 2s linear;
    }
  }
}


@keyframes disable {
  0% {
    opacity: 1;
    transform: none;
  }

  50% {
    opacity: 0;
    transform: scale(0.6) translateY(-10vh);
  }

  100% {
    transform: scale(0.6) translateY(0);
  }
}
