@import '/styles/variables';

.card {
  position: relative;
  z-index: 2;
  display: flex;
  //max-width: 288px;
  min-height: 380px;
  width: 100%;
  flex-direction: column;

  @include min768 {
    //max-width: calc(50% - 4px);
    min-height: 440px;
  
    @media (max-height: 600px) {
      min-height: 440px * 0.75;
    }
  }
  @include min1280 {
    //max-width: 448px;
    min-height: 580px;
  
    @include maxHeight {
      min-height: 530px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: $orange;
    z-index: -1;
    border-radius: 64px;

    @include min768 {
      border-radius: 104px;
    }
    @include min1280 {
      border-radius: 120px;
    }
  }

  &:hover {
    .wrap {
      transform: translate3d(-12px, -12px, 0);

      &::after {
        opacity: 1;
      }
    }
  }
}

.wrap {
  flex: 1 0 auto;
  background: $white;
  border-radius: 64px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 8px 8px 24px;
  box-sizing: border-box;
  transition: transform 0.3s $ease;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;

  @include min768 {
    border-radius: 104px;
  }
  @include min1280 {
    padding: 16px 16px 40px;
    border-radius: 120px;
  }


  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid currentcolor;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s $ease;
    border-radius: 64px;

    @include min768 {
      border-radius: 104px;
    }
    @include min1280 {
      border-radius: 120px;
    }
  }
}


.wide {
  @include min768 {
    gap: 8px;
    padding: 8px;
    align-items: flex-start;

    .image {
      margin-bottom: 0;
      min-height: 100%;
    }

    .tag {
      margin-top: 48px;
    }

    .text {
      padding: 40px 7.5%;
      box-sizing: border-box;
      text-align: left;
      align-items: baseline;
    }

    .image,
    .text {
      min-width: calc(50% - 4px);
      max-width: calc(50% - 4px);
    }
  }
  @include min1280 {
    padding: 16px;

    .tag {
      margin-top: 64px;
    }

    .text {
      padding: 104px 7.5%;
    }
  }
}

.image {
  width: 100%;
  object-fit: cover;
  border-radius: 56px;
  overflow: hidden;
  min-height: 220px;
  max-height: 220px;
  margin-bottom: 24px;

  @include min768 {
    min-height: 268px;
    max-height: 268px;
    border-radius: 96px;
  
    @media (max-height: 600px) {
      min-height: 268px * 0.75;
      max-height: 268px * 0.75;
    }
  }
  @include min1280 {
    margin-bottom: 40px;
    min-height: 340px;
    max-height: 340px;
    border-radius: 104px;
  
    @include maxHeight {
      min-height: 320px;
      max-height: 320px;
    }
  }
}

.text {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  
  @include min768 {
    padding: 0;
  }
}

.title {
  color: $black;

  &.full {
    margin-bottom: 8px;

    @include min1280 {
      margin-bottom: 24px;
    }
  }

  &.short { //когда строк много, а ограничение по символам не спасет
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 24px;
  }
}

.subtext {
  color: $black;
}

.description {
  max-width: 70%;
}

.tag {
  border: 1px solid $orange30;
  composes: small from global;
  padding: 2px 16px;
  border-radius: 32px;
  min-height: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-top: 24px;

  @include min768 {
    margin-top: auto;
  }
}
