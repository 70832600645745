@import '../../../../../../styles/variables';

.primitive {
  composes: article-wrapper from global;
  composes: text from global;
  margin-bottom: 48px;
  overflow: hidden;

  @include min768 {
    margin-bottom: 64px;

    @media (max-height: 600px) {
      margin-bottom: 64px * 0.75;
    }
  }
  @include min1280 {
    margin-bottom: 80px;
  }

  p {
    color: $black;
  }
  
  

  *:not(a) {
    background: transparent !important;
    color: $black !important;
  }
  
  h2, h3, h4 {
    color: $orange!important;
    
    & > * {
      color: $orange!important;
    }
    
    & + * {
      margin-top: 16px;
      
      @include min1280 {
        margin-top: 24px;
      }
    }
  }
  
  
  a {
    hyphens: auto;
    background: transparent !important;
    color: $orange !important;
  }

  table {
    border: 1px solid $orange;
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 800px;
    width: 100%;

    ul, ol {
      margin-bottom: 0;
    }

    ol li + li,
    ul li + li {
      margin-top: 12px;
    }

    ul li {
      padding-left: 16px;

      @include min1280 {
        padding-left: 24px;
      }


      &::before {
        top: 9px;
      }
    }
  }

  td {
    padding: 15px 7px 14px 10px;
    border-bottom: 1px solid $orange;
    //width: 25%;

    &:not(:last-of-type) {
      border-right: 1px solid $orange;
    }
  }


  [class='quill-better-table-wrapper'] {
    overflow: auto;
    max-width: 100%;
    margin-bottom: 24px;
  
    @include min768 {
      margin-bottom: 32px;
    }
    @include min1280 {
      margin-bottom: 40px;
    }

    @include min768 {
      margin-right: -66px;
      margin-left: -66px;
      max-width: calc(100% + 132px);
    }
    @include min1280 {
      margin-right: -188px;
      margin-left: -188px;
      max-width: calc(100% + 372px);
    }
  }
}
