@import '../../../../../styles/variables';

.textInfo {
  display: flex;
  flex-direction: column;
  composes: small from global;
  
  @include max768 {
    font-size: 16px;
    line-height: 1.4;
  }
  
  span {
    display: inline-block;
  }
}
