@import 'STYLES/variables';

.main {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  color: $white;
  overflow: hidden;
  z-index: 4;
  margin-top: 80px;

  @include min768 {
    margin-top: 120px;
  }
  @include min1280 {
    margin-top: 0;
    padding-top: 200px;
    padding-bottom: 200px;
  }

  &.active {
    .title span {
      opacity: 1;

      &:nth-child(odd) {
        transform: translateX(0);
      }

      &:nth-child(even) {
        transform: translateX(0);
      }
    }

    .circle {
      opacity: 0.16;
      transition: opacity 0.3s $ease 0.5s;
    }
  }
}

.container {
  position: relative;
}


.title {
  h2 {
    display: flex;
    flex-direction: column;
    line-height: 1.1;
    padding-bottom: .4em;
    margin-bottom: -.4em;
  }

  span {
    box-sizing: border-box;
    display: inline-block;
    padding-right: 2.5rem;
    opacity: 0;
    transition:
      opacity 0.4s $ease 0.33s,
      transform 0.4s $ease 0.33s;

    &:nth-of-type(odd) {
      transform: translateX(-50%);
    }

    &:nth-of-type(even) {
      transform: translateX(50%);

      @include min480 {
        margin-left: 16.67%;
      }
      @include min1280 {
        margin-left: auto;
      }
    }
  }
}


.circle {
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $white;
  width: 140%;
  padding-top: 140%;

  @include min768 {
    width: 100%;
    padding-top: 100%;
  }

  &::before,
  &::after,
  div::before,
  div::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    border: 1px solid $white;
  }

  &::before {
    width: 5%;
    height: 5%;
  }

  &::after {
    width: 25%;
    height: 25%;
  }

  div::before {
    width: 50%;
    height: 50%;
  }

  div::after {
    width: 75%;
    height: 75%;
  }
}

.orange {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: $orange;
  opacity: 1;
  z-index: -1;
}
