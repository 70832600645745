@import '../../../../../../styles/variables';

.block {
  composes: article-wrapper from global;
}

.wrap {
  @include min768 {
    display: flex;
    align-items: center;
  }
}

.avatar {
  width: 104px;
  height: 104px;
  margin-bottom: 16px;
  position: relative;
  left: -2px;
  top: 0;
 
  @include min768 {
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    margin-bottom: 0;
    margin-right: 24px;
    top: 0;
    left: -3px;
  }
  @include min1280 {
    width: 144px;
    height: 144px;
    min-width: 144px;
    min-height: 144px;
    margin-right: 32px;
    top: 0;
  }

  img {
    border: 4px solid $orange;
    border-radius: 50%;
    overflow: hidden;
    transform: translate3d(0,0,0.3px);
    z-index: 1;
  }


  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}


.info {
  display: flex;
  flex-direction: column;

  @include min768 {
    font-size: 12px;
  }
}

.position {
  font-family: $nobile;
  font-size: 10px;
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $black;
  //margin-bottom: 4px;

  @include min1280 {
    //margin-bottom: 8px;
    font-size: 12px;
  }
}

.mail {
  color: $orange;
  font-family: $hagrid;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4;
  hyphens: auto;
  //margin-bottom: 16px;
  transition: 0.3s ease;

  @include min768 {
    font-size: 20px;
    //margin-bottom: 24px;
  }
  @include min1280 {
    font-size: 24px;
    //margin-bottom: 32px;
  }

  &:hover {
    opacity: 0.85;
  }

  &:active {
   // opacity: 0.7;
  }
}

.button {
  align-self: flex-start;
  margin-top: 16px;

  @include min1280 {
    margin-top: 24px;
  }
}
