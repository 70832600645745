@import 'STYLES/variables';


.radio {
  margin-top: 15px;
  
  @include max1280 {
    column-count: 2;
    column-gap: 16px;
  }
  @media(max-width: 400px) {
    column-gap: 0;
    column-count: 1;
  }

  label {
    break-inside: avoid;
  }

  &:first-child {
    margin-top: 0;
  }
}

.item {
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.3s ease;
  padding-top: 2px;
  padding-bottom: 6px;
  min-height: 24px;
  box-sizing: border-box;
  line-height: 1.1;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;

  @include min1280 {
    gap: 12px;
    padding-bottom: 12px;
    //min-height: 40px;
  }


  &:last-of-type {
    //margin-bottom: 0;
  }

  input {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 50%;

    &:checked + .circle {
      border-color: $orange;

      &::after {
        opacity: 1;
      }
    }
  }

  &:hover {
    opacity: 0.75;
  }

  &:active {
  //  opacity: 0.5;
  }
}

.circle {
  width: 16px;
  height: 16px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border-radius: 50%;
  transition: border 0.3s ease;
  border: 1px solid $orange;

  @include min1280 {
    width: 24px;
    height: 24px;
  }

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50px;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: $orange;

    @include min1280 {
      width: 12px;
      height: 12px;
    }
  }
}

.label {
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.04em;
  color: $orange;
}
