@import '../../../../../styles/variables';

.navigation {
  display: flex;
  align-items: flex-start;

  &.footerNav {
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;

    li {
      margin-left: 4px;
      margin-right: 4px;
      margin-bottom: 8px;

      @include min1024 {
        margin-right: 0;
        margin-left: 8px;
      }
    }

    @include min1024 {
      justify-content: flex-end;
    }
  }

  li {
    & + li {
      margin-left: 8px;
    }
  }
}