@import '../../../../../../styles/variables';

.block {
  @include min768 {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include min1280 {
    padding-left: 24px;
    padding-right: 24px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    aspect-ratio: 2.2 / 1;
    object-fit: cover;
  }

  .picture {
    border-radius: 56px;
    overflow: hidden;

    @include min768 {
      border-radius: 104px;
    }
    @include min1280 {
      border-radius: 184px;
    }
  }
}

.content {
  border-radius: 56px;
  overflow: hidden;
  margin-bottom: 48px;

  @include min768 {
    border-radius: 104px;
    margin-bottom: 80px;
  }
  @include min1280 {
    border-radius: 184px;
    margin-bottom: 120px;
  }

}
