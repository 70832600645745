@charset "utf-8";

@use 'sass:string';

// functions
@function hashReplace($colour) {
  @if str-slice('#{$colour}', 0, 1) == '#' {
    @return '%23' + str-slice('#{$colour}', 2, -1);
  }

  @return $colour;
}

// fonts
$hagrid: 'Hagrid', sans-serif;
$ibm: 'IBMPlexSans', sans-serif;
$jeff: 'JeffScript', sans-serif;
$nobile: 'Nobile', sans-serif;

// colors
$bg: #FEEEE9;
$white: #FFF;
$black: #121212;
$yellow: #F7A01D;
$orange: #F05125;
$red: #F51B1B;
$purple: #92278F;
$green: #03907F;
$pig: rgb(240 81 37 / 10%);
$pig2: #FCDED5;

//colors: soft 30
$orange30: #FBCBBE;
$purple30: #E8C3E7;
$green30: #B3DED9;
$yellow30: #FDE2BB;
$black30: #3d3d3d;
$white30: #858585;

//colors: soft 10
$orange10: #FEEEE9;
$purple10: #F4E9F4;
$green10: #E6F4F2;
$yellow10: #FEF5E8;


// defaults
$defaultText: $ibm;
$defaultTextColor: $black;
$selectionColor: $white;
$selection: $orange;

// easing
$ease: ease;
$easeOut: cubic-bezier(0.19, 1, 0.22, 1);
$easeIn: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInOut: cubic-bezier(0.35, 0, 0, 1.01);


// patterns


// media queries
@mixin max480 {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin min480 {
  @media (min-width: 481px) {
    @content;
  }
}
@mixin max768 {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin min768 {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin max1024 {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin min1024 {
  @media (min-width: 1025px) {
    @content;
  }
}
@mixin max1280 {
  @media (max-width: 1279px) {
    @content;
  }
}
@mixin min1280 {
  @media (min-width: 1280px) {
    @content;
  }
}
@mixin max1440 {
  @media (max-width: 1439px) {
    @content;
  }
}
@mixin min1440 {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin min1600 {
  @media (min-width: 1600px) {
    @content;
  }
}
@mixin min1920 {
  @media (min-width: 1920px) {
    @content;
  }
}
@mixin outsideContainer {
  @media (min-width: 1466px) {
    @content;
  }
}
@mixin pointsTablet {
  @media screen and (min-width: 768px) and (max-width: 1280px) {
    @content;
  }
}
@mixin maxHeight {
  @media screen and (max-height: 740px) {
    @content;
  }
}


@mixin h4 {
  font: 700 22px/1.3 $hagrid;
  letter-spacing: 0;
  
  @include min768 {
    line-height: 1.2;
    font-size: 28px;
    
    @media (max-height: 600px) {
      font-size: 28px * 0.75;
    }
  }
  @include min1280 {
    font-size: 40px;
    line-height: 1.1;
    
    @media (max-height: 600px) {
      font-size: 40px * 0.75;
    }
  }
}

// mixins
@mixin stroke($color: #000, $size: 1px) {
  text-shadow:
    -#{$size} -#{$size} 0 $color,
    0         -#{$size} 0 $color,
    #{$size}  -#{$size} 0 $color,
    #{$size}   0 0 $color,
    #{$size}   #{$size} 0 $color,
    0          #{$size} 0 $color,
    -#{$size}  #{$size} 0 $color,
    -#{$size} 0 0 $color;
}
@mixin pseudo($el: before, $height: 100%) {
  &::#{$el} {
    content: '';
    width: 0;
    height: $height;
    display: inline-block;
    vertical-align: middle;
  }
}
@mixin rect($paddingBottom: 100%) {
  &::before {
    content: '';
    width: 100%;
    display: block;
    padding-bottom: $paddingBottom;
    pointer-events: none;
  }
}
@mixin justify() {
  text-align: justify;
  font-size: 0;

  &::after {
    content: '';
    width: 100%;
    height: 0;
    display: inline-block;
  }
}
@mixin center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin fb($width, $height, $fill, $color) {
  background: string.unquote($color) url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'#{$width}\' height=\'#{$height}\' viewBox=\'0 0 20 20\'><path fill=\'#{hashReplace($fill)}\' d=\'M6,7.1h1.7V5.4c0-0.8,0-1.9,0.5-2.6C8.8,2,9.6,1.5,10.9,1.5c2.1,0,3,0.3,3,0.3l-0.4,2.6c0,0-0.7-0.2-1.4-0.2c-0.7,0-1.2,0.2-1.2,0.9v2h2.7l-0.2,2.5h-2.5v8.8H7.7V9.7H6V7.1z\'/></svg>') no-repeat center center;
}
@mixin tw($width, $height, $fill, $color) {
  background: string.unquote($color) url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'#{$width}\' height=\'#{$height}\' viewBox=\'0 0 20 20\'><path fill=\'#{hashReplace($fill)}\' d=\'M17.9,5.1c-0.6,0.3-1.2,0.4-1.9,0.5c0.7-0.4,1.2-1,1.4-1.8c-0.6,0.4-1.3,0.6-2.1,0.8c-0.6-0.6-1.4-1-2.4-1c-1.8,0-3.3,1.5-3.3,3.3c0,0.3,0,0.5,0.1,0.7C7.2,7.4,4.8,6.1,3.2,4.1c-0.3,0.5-0.4,1-0.4,1.6c0,1.1,0.6,2.1,1.4,2.7c-0.5,0-1-0.2-1.5-0.4v0c0,1.6,1.1,2.9,2.6,3.2c-0.3,0.1-0.6,0.1-0.9,0.1c-0.2,0-0.4,0-0.6-0.1c0.4,1.3,1.6,2.2,3,2.3c-1.1,0.9-2.5,1.4-4,1.4c-0.3,0-0.5,0-0.8,0c1.4,0.9,3.2,1.5,5,1.5c6,0,9.3-5,9.3-9.3c0-0.1,0-0.3,0-0.4C16.9,6.3,17.5,5.7,17.9,5.1\'/></svg>') no-repeat center center;
}
@mixin ig($width, $height, $fill, $color) {
  background: string.unquote($color) url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'#{$width}\' height=\'#{$height}\' viewBox=\'0 0 20 20\'><circle fill=\'#{hashReplace($fill)}\' cx=\'10\' cy=\'10\' r=\'2.5\'/><path fill=\'#{hashReplace($fill)}\' d=\'M17.5,6.9c0-0.8-0.2-1.3-0.3-1.8c-0.2-0.5-0.4-0.9-0.9-1.3c-0.4-0.4-0.8-0.7-1.3-0.9c-0.5-0.2-1-0.3-1.8-0.3s-1.1,0-3.1,0s-2.3,0-3.1,0S5.7,2.8,5.2,2.9c-0.6,0.2-1,0.4-1.4,0.9C3.3,4.2,3.1,4.6,2.9,5.1c-0.2,0.5-0.3,1-0.3,1.8s0,1.1,0,3.1s0,2.3,0,3.1s0.2,1.3,0.3,1.8c0.2,0.5,0.4,0.9,0.9,1.3c0.4,0.4,0.8,0.7,1.3,0.9c0.5,0.2,1,0.3,1.8,0.3s1.1,0,3.1,0s2.3,0,3.1,0s1.3-0.2,1.8-0.3c0.5-0.2,0.9-0.4,1.3-0.9c0.4-0.4,0.7-0.8,0.9-1.3c0.2-0.5,0.3-1,0.3-1.8s0-1.1,0-3.1S17.5,7.7,17.5,6.9z M10,13.9c-2.1,0-3.9-1.7-3.9-3.9S7.9,6.1,10,6.1s3.9,1.7,3.9,3.9S12.1,13.9,10,13.9z M14,6.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S14.5,6.9,14,6.9z\'/></svg>') no-repeat center center;
}
@mixin yt($width, $height, $fill, $color) {
  background: string.unquote($color) url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'#{$width}\' height=\'#{$height}\' viewBox=\'0 0 20 20\'><path fill=\'#{hashReplace($fill)}\' d=\'M14.3,4.6H5.7c-1.8,0-3.2,1.4-3.2,3.2v4.3c0,1.8,1.4,3.2,3.2,3.2h8.6c1.8,0,3.2-1.4,3.2-3.2V7.9C17.5,6.1,16.1,4.6,14.3,4.6z M8.7,12.7V7.3l3.5,2.7L8.7,12.7z\'/></svg>') no-repeat center center;
}
@mixin pn($width, $height, $fill, $color) {
  background: string.unquote($color) url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'#{$width}\' height=\'#{$height}\' viewBox=\'0 0 20 20\'><path fill=\'#{hashReplace($fill)}\' d=\'M10.4,1.6c-4.6,0-6.9,3.3-6.9,6c0,1.7,0.6,3.1,2,3.7c0.2,0.1,0.4,0,0.5-0.2c0-0.2,0.1-0.6,0.2-0.8c0.1-0.2,0-0.3-0.1-0.5C5.6,9.3,5.4,8.7,5.4,7.9c0-2.4,1.8-4.6,4.7-4.6c2.6,0,4,1.6,4,3.7c0,2.8-1.2,5.1-3.1,5.1c-1,0-1.8-0.8-1.5-1.9c0.3-1.2,0.9-2.5,0.9-3.4c0-0.8-0.4-1.4-1.3-1.4c-1,0-1.9,1.1-1.9,2.5c0,0.9,0.3,1.5,0.3,1.5s-1.1,4.5-1.2,5.3c-0.4,1.6-0.1,3.5,0,3.7c0,0.1,0.2,0.1,0.2,0.1c0.1-0.1,1.3-1.6,1.7-3.2c0.1-0.4,0.7-2.7,0.7-2.7c0.3,0.6,1.3,1.2,2.4,1.2c3.1,0,5.2-2.8,5.2-6.6C16.5,4.3,14.1,1.6,10.4,1.6\'/></svg>') no-repeat center center;
}
@mixin in($width, $height, $fill, $color) {
  background: string.unquote($color) url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'#{$width}\' height=\'#{$height}\' viewBox=\'0 0 20 20\'><path fill=\'#{hashReplace($fill)}\' d=\'M19,11.9v6.7h-3.9v-6.2c0-1.6-0.6-2.6-2-2.6c-1.1,0-1.7,0.7-2,1.4c-0.1,0.2-0.1,0.6-0.1,0.9v6.5H7.2c0,0,0.1-10.5,0-11.6h3.9v1.6c0,0,0,0,0,0h0v0c0.5-0.8,1.4-1.9,3.5-1.9C17.1,6.7,19,8.4,19,11.9z M3.2,1.4C1.9,1.4,1,2.3,1,3.4c0,1.1,0.8,2,2.1,2h0c1.3,0,2.2-0.9,2.2-2C5.3,2.3,4.5,1.4,3.2,1.4z M1.2,18.6h3.9V7H1.2V18.6z\'/></svg>') no-repeat center center;
}
@mixin dashed-box($borderRadius: 32,  $borderWidth: 2) {
  overflow: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml,<svg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'><rect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'' + $borderRadius + '\' ry=\'' + $borderRadius + '\' stroke=\'%23F05125\' stroke-width=\'2\' stroke-dasharray=\'5px\' stroke-dashoffset=\'5px\' stroke-linecap=\'butt\'/></svg>');
  //background-image: url('data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'' + $borderRadius + '\' ry=\'' + $borderRadius + '\' stroke=\'%23' + $colorBox + '\' stroke-width=\'' + $borderWidth + '\' stroke-dasharray=\'6px, 6px\' stroke-dashoffset=\'15px\' stroke-linecap=\'butt\'/%3e%3c/svg%3e');
  border-radius: $borderRadius + px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
