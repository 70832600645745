@import '../../../../styles/variables';

.block {
  overflow: hidden;
  //margin-bottom: 60px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: 32px;
  color: $orange;
  text-align: center;

  @include min768 {
    margin-bottom: 48px;
  }
  @include min1280 {
    margin-bottom: 64px;
  }
}

.slider {
  @include min768 {
    max-width: calc(100% / 8 * 6);
  }
  @include min1280 {
    max-width: calc(100% / 12 * 8);
  }
}
