@import '../../../../../styles/variables';

.item {

}


.item-opened {
  .icon {
    transform: translateY(2px) rotate(0);
  }
}

.wrap {
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    transform-origin: 50% 50%;
    transform: translateY(0) rotate(180deg);

    @include center-center;
  }
}

.icon {
  transition: transform 0.3s $ease;

  svg {
    fill: $orange;
  }
}

.link {
  font-family: $hagrid;
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;

  @include min768 {
    font-size: 40px;
    line-height: 1em;
  }


  &::after {
    display: none;
  }

  &.active {
    color: $white;
    text-shadow:
      -1px -1px 0 $orange,
      1px -1px 0 $orange,
      -1px 1px 0 $orange,
      1px 1px 0 $orange;

    & + .icon {
      svg {
        fill: $white;
        stroke: $orange;
        stroke-width: 1.5px;
      }
    }
  }
}

.drop-list {
  //display: none;

  &.active {
   // display: block;
  }
}
