@import '/styles/variables';

.block {
  box-sizing: border-box;
  margin-bottom: 48px;

  * {
    box-sizing: border-box;
  }

  @include min768 {
    margin-bottom: 80px;
  }
  @include min1280 {
    margin-bottom: 120px;
  }
}

.mainOffice {
  width: 100%;
  background-color: $white;
  padding: 40px 16px;
  min-height: 360px;
  border-radius: 64px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  text-align: center;

  @include min768 {
    border-radius: 104px;
    padding-top: 56px;
    padding-bottom: 64px;
    align-items: center;
    justify-content: center;
  }
  @include min1280 {
    min-height: 640px;
    border-radius: 200px;
    padding-bottom: 120px;
  }
  
  .name {
    margin-bottom: 8px;
    color: $orange;
    
    @include min1280 {
      margin-bottom: 16px;
    }
  }
  
  .address {
    margin: 0 auto;
    text-align: center;
    max-width: 300px;
    
    @include min768 {
      max-width: 350px;
    }
    @include min1280 {
      max-width: 500px;
    }
  }
}


.icon {
  position: relative;
  width: 72px;
  height: 72px;
  margin: 0 auto 20px;

  @include center-center;
  @include min1280 {
    width: 136px;
    height: 136px;
    margin-bottom: 40px;
  }
}

.outer {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.additional {
  margin-top: 8px;
  text-align: center;

  @include min768 {
    padding-right: 24px;
    padding-left: 24px;
    text-align: left;
  }
  @include min1280 {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.offices {
  background-color: $pig;
  padding: 48px 16px 64px;
  border-radius: 64px;
  display: flex;
  flex-flow: row wrap;
  gap: 32px;

  @include min768 {
    padding: 56px 15% 54px;
    border-radius: 104px;
    gap: 32px 64px;
  }
  @include min1280 {
    padding: 120px calc(100% / 12 * 1) 120px calc(100% / 12 * 2);
    border-radius: 200px;
    gap: 80px 32px;
  }

  li {
    width: 100%;
    
    @include min768 {
      width: calc(50% - 32px);
    }
    @include min1280 {
      width: calc(100% / 3 - 22px);
    }
  }

  .name {
    color: $orange;
    margin-bottom: 8px;
  }
}
