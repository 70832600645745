@import '/styles/variables';


.main {
  overflow: hidden;
  margin-top: 80px;

  @include min768 {
    margin-top: 120px;
  }
  @include min1280 {
    margin-top: 200px;
  }
}

.title {
  color: $orange;
  text-align: center;
  margin-bottom: 32px;
  
  @include min768 {
    margin-bottom: 48px;
  }
  @include min1280 {
    margin-bottom: 64px;
  }
}

.slider {

}
