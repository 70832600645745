@import '../../../../styles/variables';

.section {
  max-width: 1466px;
  width: 100%;
  margin: 0 auto 64px;
  box-sizing: border-box;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  @include min768 {
    padding-right: 24px;
    padding-left: 24px;
    margin-bottom: 120px;
  }
  @include min1280 {
    padding-right: 40px;
    padding-left: 40px;
    margin-bottom: 200px;
  }

  &.vacancy {
    margin-bottom: 8px;

    .content {
      padding-top: 64px;

      @include min768 {
        padding-top: 80px;
      }
      @include min1280 {
        padding-top: 120px;
      }
    }
  }

  &.deleteAccount,
  &.policy,
  &.terms,
  &.cookies {
    margin-top: 80px;
    margin-bottom: 0;

    @include min768 {
      margin-top: 96px;
    }
    @include min1280 {
      margin-top: 104px;
    }


    h1, h2, h3, h4, h5, h6 {
      color: $orange;
    }

    h1, h2 {
      & + p {
        margin-top: 16px;

        @include min768 {
          margin-top: 24px;
        }
      }
    }

    h3, h4, h5, h6 {
      margin-bottom: 24px;

      @include min768 {
        margin-bottom: 32px;
      }
      @include min1280 {
        margin-bottom: 40px;
      }
    }

    .content {
      padding-top: 64px;

      @include min768 {
        padding-top: 80px;
      }
      @include min1280 {
        padding-top: 120px;
      }
    }
  }
}

.content {
  background-color: $white;
  border-radius: 64px;
  padding: 8px 8px 64px;

  @include min768 {
    border-radius: 120px;
    padding: 16px 0 64px;
  }
  @include min1280 {
    border-radius: 200px;
    padding-top: 24px;
    padding-bottom: 104px;
  }
  
  
  &[class *='ql-direction-rtl'] {
    [data-id='agreement'],
    button,
    label, input,
    select {
      text-align: right;
    }
    
    input {
      padding: 11px 24px 10px 44px;
      
      @include min768 {
       padding: 14px 32px 13px 52px;
      }
    }
    
    [data-id="warning"] {
      right: auto;
      left: 10px;
    }
    label span {
      left: auto;
      right: 0;
    }
    
    
  }
}
