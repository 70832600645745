@import '../../../../../styles/variables';

.link {
  display: inline-flex;
  align-items: center;

  svg {
    width: 89px;
    height: 16px;
    display: block;
    fill: $black;
    transition: fill 0.3s ease;
  }

  &:hover {
    svg {
      fill: $red;
    }
  }
}