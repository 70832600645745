@import '/styles/variables';

.tag {
  display: inline-block;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: $orange;
  padding: 3px 16px;
  border: 1px solid $orange30;
  border-radius: 32px;

  &.date {
    border-color: $orange;
  }

  @include min1280 {
    font-size: 18px;
  }
}
