@import '/styles/variables';

.main {
  padding-top: 80px;

  @include min768 {
    padding-top: 120px;
  }
  @include min1280 {
    padding-top: 200px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 48px;
  position: relative;
  padding: 64px 0;
  color: $orange;
  z-index: 2;

  @include min768 {
    gap: 8px;
    flex-direction: row;
    align-items: center;
    padding: 80px calc(100% / 8 * 1);
  }
  @include min1280 {
    padding: 136px calc(100% / 12 * 2);
    gap: 72px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: -16px;
    right: -16px;
    bottom: 0;
    border-radius: 64px;
    background: $orange;
    opacity: 0.16;
    
    @include min768 {
      left: 0;
      right: 0;
      border-radius: 104px;
    }
    @include min1280 {
      border-radius: 200px;
    }
  }
}

.text {
  text-align: center;
  width: 100%;

  @include min768 {
    text-align: left;
    margin-right: auto;
    min-width: 40%;
    max-width: 350px;
  }
  @include min1280 {
    min-width: 60%;
    max-width: 60%;
  }
}

.subtext {
  margin-top: 8px;

  @include pointsTablet {
    max-width: 33ch;
  }
  @include min1280 {
    margin-top: 24px;
    max-width: 510px;
  }
}

.btn {
  margin-top: 24px;

  @include min768 {
    margin-top: 32px;
  }
  @include min1280 {
    margin-top: 40px;
  }
}

.image {
  display: flex;
  justify-content: center;
  min-width: 166px;
  max-width: 166px;
  height: 166px;

  @include max768 {
    margin-right: auto;
    margin-left: auto;
  }
  @include min768 {
    min-width: 180px;
    max-width: 180px;
    height: 180px;
  }
  @include min1280 {
    min-width: 340px;
    max-width: 340px;
    height: 340px;
  }
}


.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
