@import '/styles/variables';

.wrap {
  margin: auto;
  max-width: 900px;
  text-align: center;
  color: $orange;

  .code {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 820;
    font-size: 83px;
    margin-bottom: 32px;

    @include min768 {
      font-size: 173px;
      margin-bottom: 48px;
    }
    @include min1280 {
      font-size: 260px;
      margin-bottom: 80px;
    }
  }


  h4 {
    margin-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;

    @include min768 {
      margin-bottom: 32px;
    }
    @include  min1280 {
      margin-bottom: 40px;
    }
  }

  .placeholder {
    width: 100%;
    height: 100%;
    background: red;
  }
}

.video {
  width: 118px;
  height: 118px;
  object-fit: contain;
  
  @include min768 {
    width: 244px;
    height: 244px;
  }
  @include min1280 {
    width: 368px;
    height: 368px;
  }
}
