@import '/styles/variables';


.block {
  @include min1280 {
    height: 200vh;
    //padding-top: 100px;
    //padding-bottom: 100px;
  }
}

.wrap {
  position: relative;
  height: 100%;

  @include max1280 {
    height: 520px;
    overflow: hidden;
  }
  @include min1280 {
    height: 100vh;
    position: sticky;
    top: 0;
    overflow: hidden;
  }
}

.map {
  position: relative;
  height: 100%;
  pointer-events: none;

  @include min1280 {
    overflow: hidden;
  }
 
  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-56%, 0%, 0.05px);
    display: block;

    @include min1280 {
      max-width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0.05px);
      transform-origin: 100% 100%;
    }
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  @include min1280 {
   // will-change: transform;
  }
}

.stickerWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //transform: translate3d(120px, -85px, 0);
}

.stickers {
  width: 268px;
  height: 268px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
  z-index: 2;
  //border: 1px solid red;

  @include min768 {
    transform: translate(-50%, -50%);
  }
  @include min1280 {
    will-change: transform;
    transform: translate(-40%, -68%) scale(0.25);
    //transform: translate(-85%, -55%);
    width: 444px;
    height: 513px;
  
    &.active {
      
      [data-item='inner'] {
        opacity: 1;
      }
    
      [data-item='sign'] {
        transform: scaleX(1);
      
        span {
          opacity: 1;
        }
      }
    }
  }
}

.sticker {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  // можно не по порядку сделать, а по модификаторам, пока так
  &:nth-of-type(1) {
    top: 20%;
    right: -5%;

    @include min1280 {
      top: 10%;
      right: -5%;
    }
  }

  &:nth-of-type(2) {
    top: 34%;
    left: 39%;

    @include min1280 {
      top: 25%;
      left: 39%;
    }
  }

  &:nth-of-type(3) {
    top: 49%;
    right: 3%;

    @include min1280 {
      top: 36%;
      right: 0;
    }
  }

  &:nth-of-type(4) {
    top: 66%;
    left: 19%;

    @include min1280 {
      top: 63%;
      left: 37%;
    }
  }

  &:nth-of-type(5) {
    top: 78%;
    right: -12%;

    @include min1280 {
      top: 70%;
      right: -10%;
    }
  }

  &:nth-of-type(6) {
    top: 97%;
    left: 39%;

    @include min1280 {
      top: 89%;
      left: 50%;
    }
  }
}
