@import '../../../../../styles/variables';

.item {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;

  @include min1280 {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
  }

  span {
    width: 100%;
    height: 100%;
    border: 1px solid $orange;
    background-color: transparent;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    border-radius: 50%;

    @include center-center;

    &:hover {
      background-color: $orange;
      border-color: $orange;

      svg {
        fill: $white;
      }
    }

    svg {
      width: 32px;
      height: 32px;
      display: block;
      fill: $orange;
      transition: fill 0.3s ease;
    }
  }
}



