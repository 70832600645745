@import '../../../../styles/variables';


.main {
  margin-top: 80px;

  @include min768 {
    margin-top: 120px;
  }
  @include min1280 {
    margin-top: 160px;
  }
}

.title {
  position: relative;
  color: $orange;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: .4em;
  margin-bottom: -.4em;

  @media (max-width: 350px) {
    font-size: 38px;
  }

  @include min1280 {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  span {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }


  @include min1280 {
    justify-content: center;
    gap: 35px 0;
  }
}

.divider {
  width: 100%;
}

.media {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  gap: 8px;

  @include min768 {
    margin-top: 64px;
    flex-direction: row;
    justify-content: center;
  
    @media(max-height: 600px) {
      margin-top: 48px;
    }
  }
  @include min1280 {
    margin-top: 112px;
  }

  .item {
    position: relative;
    background-color: #fff;
    border-radius: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
    transition: all 0.5s ease;
    padding-bottom: 16px;
    width: 100%;

    @include min1024 {
      max-width: 450px;
    }

    &Title {
      padding-top: 24px;
      margin-bottom: 48px;
      color: $orange;
  
      @media(max-height: 600px) {
        margin-bottom: 32px;
      }

      @include min1280 {
        margin-bottom: 84px;
      }
    }

    @include min1280 {
      border-radius: 104px;
      padding-bottom: 40px;
    }


    &:hover {
      transform: translate3d(-12px, -12px, 0);
      box-shadow: 12px 12px $orange;

      &::after {
        opacity: 1;
        transform: translate3d(12px, 12px, 0);
      }
    }
  }
}


.itemSoc {
  position: relative;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;

  @include min1280 {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
  }

  span {
    width: 100%;
    height: 100%;
    border: 1px solid $orange;
    background-color: transparent;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    border-radius: 50%;
    pointer-events: auto;

    @include center-center;

    &:hover {
      background-color: $orange;
      border-color: $orange;

      svg {
        fill: $white;
      }
    }

    svg {
      width: 32px;
      height: 32px;
      display: block;
      fill: $orange;
      transition: fill 0.3s ease;
    }
  }
}


.squirrel {
  min-width: 87px;
  max-width: 87px;
  height: 87px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: $white;
  border-radius: 50%;
  margin-right: 1rem;

  @include min768 {
    min-width: 104px;
    max-width: 104px;
    height: 70px;
    background: transparent;
  
    @media(max-height: 600px) {
      min-width: 104px * 0.75;
      max-width: 104px * 0.75;
      height: 70px * 0.75;
    }
  }
  @include min1280 {
    min-width: 158px;
    max-width: 158px;
    height: 92px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 86px;
    height: 86px;
    border-radius: 50%;
    background: $white;
    z-index: -1;

    @include min768 {
      width: 102px;
      height: 102px;
  
      @media(max-height: 600px) {
        width: 102px * 0.75;
        height: 102px * 0.75;
      }
    }
    @include min1280 {
      width: 150px;
      height: 150px;
      top: 54%;
    }
  }

  img {
    border-radius: 100%;
  }
}

.squirrel-animation {
  animation: rotate 10s linear infinite;
  position: absolute;
  z-index: 4;
  height: 89px;
  width: 89px;

  @include min768 {
    width: 106px;
    height: 106px;
    top: -18px;
  
    @media(max-height: 600px) {
      width: 106px * 0.75;
      height: 106px * 0.75;
      top: -18px * 0.75;
    }
  }
  @include min1280 {
    width: 152px;
    height: 152px;
    top: -26px;
  }
}

.squirrel-img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  position: relative;

  @include min768 {
    width: 70px;
    height: 70px;
    margin-bottom: 0;
    top: 10px;
    
    @media(max-height: 600px) {
      width: 70px * 0.75;
      height: 70px * 0.75;
    }
  }
  @include min1280 {
    width: 100px;
    height: 100px;
    top: 20px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
