@import 'STYLES/variables';

.main {
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 20px;

  @include min768 {
    padding-top: 120px;
  }
  @include min1280 {
    padding-top: 156px;
  }
}


.wrap {

}

.location {
  display: flex;
  flex-direction: column;

  @include min768 {
    flex-direction: row;
  }
}

.slider {
  overflow: visible !important;
  max-width: 100%;

  :global(.swiper-slide) {
    width: auto;
  }
}

.image-wrap {
  display: block;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  object-fit: cover;
  object-position: center;
  border: 8px solid $white;
  border-radius: 64px;
  height: 100%;
  max-height: 360px;
  overflow: hidden;
  opacity: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include min768 {
    max-height: 100%;
  }
  @include min1280 {
    border-width: 16px;
    border-radius: 120px;
  }
}


.images {
  position: relative;
  min-height: 360px;

  @include min768 {
    width: 40%;
    max-width: 520px;
  }
  @include min1280 {
    max-height: 630px;
    min-height: 630px;
    margin-top: auto;
    margin-bottom: auto;
  }
  @include outsideContainer {
    width: 50%;
    max-width: 560px;
  }
}

.pin {
  position: absolute;
  border: none;
  border-radius: 0;
  width: 124px;
  height: 92px;

  .pinIcon {
    transition: opacity 0.3s $ease 0.1s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  &:nth-of-type(2) {
    top: -10%;

    @include min768 {
      top: -15%;
    }
  }

  &:nth-of-type(3) {
    left: -20px;

    @include min768 {
      left: -20%;
    }
  }

  @include min768 {
    width: 155px;
    height: 115px;
  }
  @include min1280 {
    width: 245px;
    height: 185px;
  }
}

.block {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: opacity 0.3s $ease;
  opacity: 0;

  &.active {
    opacity: 1;

    .pinIcon,
    .image-wrap {
      opacity: 1;
    }
  }
}

.names {
  margin-bottom: 42px;

  @include min768 {
    width: 60%;
    flex-direction: column;
    margin-bottom: 0;
    padding: 0 16px;
  }
}

.title {
  composes: outline-text from global;
  line-height: 120%;
  transition: color 0.3s $ease, text-shadow 0.3s $ease;
  cursor: pointer;
  width: auto;

  @media screen and (min-width: 768px) and (max-width: 900px) {
    font-size: 46px;
    line-height: 1.3;
    margin-right: 10vw;
  }

  @include min768 {
    text-align: left;
    padding: 0;
    margin: 0 20% 0 0;
  }

  &.active {
    text-shadow: none;
    color: $orange;
  }
}

