@import '../../../../../../styles/variables';


.deleteForm {
  composes: article-wrapper from global;
  
  .formMessage,
  form {
    max-width: 450px;
    width: 100%;
  
    @include max768 {
      max-width: 100%;
    }
  }
  
  input {
    
    @include max768 {
      border-radius: 32px;
      overflow: hidden;
      padding-left: 24px!important;
      font-size: 16px!important;
      height: 48px!important;
    }
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 1000px #FFFFFF inset !important;
  }
  
}
.select {
  select {
    background-color: $white;
  }
  & > div {
    background: $white;
  }
}

.buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  @include min768 {
  }
  @include min1280 {
    width: 100%;
  }
}


.button {
  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  span {
    width: 100%;
  }
}

.agreement {
  text-align: center;
  margin-top: 8px;
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
  font-family: $ibm;
  color: $orange;

  @include min768 {
    font-size: 14px;
    text-align: left;
    margin-left: 32px;
    margin-right: 32px;
  }
  @include min1280 {
  }

  a {
    white-space: nowrap;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}


////////
.popup {

}

.form-items {
  width: 100%;
}
