@import '/styles/variables';


.main {
  overflow: hidden;
  padding-top: 128px;

  @include min768 {
    padding-top: 160px;
  }
  @include min1280 {
    padding-top: 168px;
  }
}


.title {
  color: $orange;
  text-align: center;
  margin-bottom: 48px;

  @include min768 {
    margin-bottom: 64px;
  }
  @include min1280 {
    margin-bottom: 80px;
  }
}

.block {
  box-sizing: border-box;
  background: $orange10;
  aspect-ratio: 1/0.5625;
  max-height: 90vh;
  max-width: 100%;
  width: auto;
  margin-left: auto;
  margin-right: auto;

  * {
    box-sizing: border-box;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid $orange;
    z-index: 1;
    border-radius: 24px;
    box-sizing: border-box;

    @include min768 {
      border-radius: 32px;
    }
    @include min1280 {
      border-radius: 48px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    transform-origin: center;
    transform: rotate(0deg);
    z-index: 1;
    width: 50px;
    height: 50px;
    background: url('loader.svg') no-repeat center / contain;
    border-radius: 50%;
    animation: rotate 2s linear infinite;
  }

  &.load {
    .poster-wrap {
      opacity: 1;
    }
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    justify-content: center;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.video {
  display: block;
  object-fit: contain;
  margin: 0 auto;
  max-width: 100%;
  background-color: #000;
  // height: 100%;
  //max-height: calc(100vh - 200px);
  height: auto;
  width: 100%;
  border-radius: 40px;
  border: 8px solid $orange;
  cursor: pointer;
  overflow: hidden;
  transition: border 0.3s ease;

  @include min768 {
    border-radius: 104px;
    border: 16px solid $orange;
  }
  @include min1280 {
    border-radius: 200px;
    width: 100%;
    max-height: calc(100% - 96px);
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    transform: none;
  }
}


.poster-wrap {
  position: relative;
  overflow: hidden;
  background: #000;
  backface-visibility: hidden;
  height: 100%;
  transform: translateZ(0.02px);
  opacity: 0;
  z-index: 2;
  transition: opacity 0.3s $ease;
  border-radius: 24px;

  @include min768 {
    border-radius: 32px;
  }
  @include min1280 {
    border-radius: 48px;
  }


  &:hover .play-icon {
    background-color: transparentize($orange, 0.15);
  }

  img,
  .play-icon {
    pointer-events: none;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }

  &.active {
    .play-icon,
    img {
      display: none;
    }
  }
}


.play-icon {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 4px solid $white;
  background-color: $orange;
  border-radius: 50%;
  transition: background-color 0.3s $ease;
  //pointer-events: none;
  //cursor: pointer;

  @include center-center;
  @include min768 {
    width: 96px;
    height: 96px;
    pointer-events: auto;
  }
  @include min1280 {
    width: 120px;
    height: 120px;
  }

  span {
    font-family: $ibm;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $white;

    @include min1280 {
      font-size: 18px;
    }
  }

  &:active {
    // background-color: transparentize($orange, 0.3);
  }
}


.youtube {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.panel {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 30px;

  @include min768 {
    padding: 0 90px;
  }
  @include min1280 {
    padding: 0 180px;
  }
}

.tools {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  @include min1280 {
    margin-top: 32px;
  }
}

.playbutton {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 16px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  @include center-center;

  svg {
    display: block;
  }

  &:hover {
    opacity: 0.85;
  }

  &:active {
    // opacity: 0.7;
  }
}

.play {
  width: 24px;
  height: 24px;
  stroke: $orange;
  fill: $orange;
}

.pause {
  width: 16px;
  height: 24px;
  stroke: $orange;
  fill: $orange;

  @include center-center;
}

.sound {
  width: 24px;
  min-width: 24px;
  height: 24px;
  transition: opacity 0.3s ease;
  cursor: pointer;

  @include center-center;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    // opacity: 0.7;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.cross {
  display: block;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    // opacity: 0.7;
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
}

.timeline {
  flex-grow: 1;
  width: 100%;
  height: 30px;
  margin: auto 0 auto 24px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  //pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 5px;
    border-radius: 15px;
    background-color: $orange30;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &:hover {
    .progressbar {
      background-color: transparentize($orange, 0.3);
    }
  }

  &:active {
    .progressbar {
      // background-color: transparentize($orange, 0.5);
    }
  }
}

.progressbar {
  background-color: $orange;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 2.5px);
  height: 5px;
  border-radius: 15px;
  transition: transform 0s ease, background-color 0.3s $ease;
  transform-origin: 0 50%;
  pointer-events: none;
}
