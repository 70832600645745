@import '../../../../../../styles/variables';

.block {
  composes: article-wrapper from global;
  margin-bottom: 48px;
  gap: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -32px;

  @include min768 {
    // margin-top: 32px;
    margin-top: -32px;
    margin-bottom: 64px;
  }
  @include min1280 {
    margin-top: -48px;
    margin-bottom: 120px;
    gap: 32px;
  }
}

.text {
  text-transform: uppercase;
  font: 500 12px/1 $nobile;
  white-space: nowrap;
  letter-spacing: 0.06em;
  color: $orange;
  margin-top: 5px;
  
  @include min768 {
    margin-bottom: 0;
  }
}

.select {
  direction: ltr!important;
  min-width: 200px;
  z-index: 6;
  position: relative;

  @include min768 {
    min-width: 230px;
  }
  @include min1024 {
    min-width: 270px;
  }

  select {
    background-color: $white;
  }
  & > div {
    background: $white;
  }
}
