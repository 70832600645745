@import 'styles/variables';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  transform: translateY(0);
  will-change: transform;
  transition: transform 0.3s $ease, opacity 0.3s $ease;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bg;
    border: 1px solid $orange;
    z-index: -1;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    opacity: 0;
    transition: opacity 0.2s $ease;

    @include min768 {
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }
    @include min1280 {
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 0;
    right: 0;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  &.bg {
    &::before {
      opacity: 1;
    }
  }
}

.appeared {
  opacity: 0;
  transform: translateY(-100%);
}

.hidden {
  transform: translateY(-100%);
}

@include max1024 {
  .white {
    background-color: $white;

    &::before {
      background-color: $white;
      border-color: $white;
    }

    &::after {
      background-color: $orange;
    }
  }
}

.wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;

  @include min768 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.left {
  display: flex;
  position: relative;
  z-index: 10;
}

.logo {
  @include center-center;

  a, svg {
    display: block;
  }
}

.tagline {
  display: none;

  @include min768 {
    display: flex;
    flex-direction: column;
    margin-left: 96px;
    font-family: $nobile;
    font-size: 12px;
    line-height: 1.4em;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: $orange;
    transition: color 0.3s ease;
  }
  @include min1280 {
    margin-left: 140px;
  }
}

.right {
  margin-left: auto;
}

.burger {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border: 1px solid $orange !important;
  border-radius: 50%;
  position: relative;
  transition: border-color 0.3s $ease;

  span {
    position: absolute;
    display: block;
    height: 2px;
    min-width: 22px;
    width: 22px;
    left: 50%;
    transform: translateX(-50%);
    background: $orange;
    transition: transform 0.4s $easeInOut, background-color 0.3s $ease;

    &:nth-of-type(1) {
      top: 15px;
    }

    &:nth-of-type(2) {
      top: 23px;
      transition: transform 0.2s $easeInOut;
    }

    &:nth-of-type(3) {
      top: 31px;
    }
  }

  &.active {
    span {
      &:nth-of-type(1) {
        transform: translateX(-50%) translateY(8px) rotate(45deg);
      }

      &:nth-of-type(2) {
        transform: scaleY(0);
      }

      &:nth-of-type(3) {
        transform: translateX(-50%) translateY(-8px) rotate(-45deg);
      }
    }
  }
}
