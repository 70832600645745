@import '/styles/variables';

.main {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  //margin-top: -60vh;

  @include min768 {
    //margin-top: 60px;
    overflow: initial;
  }
  @include min1280 {
    margin-top: 150px;

    @include maxHeight {
      margin-top: 100px;
    }
  }

  --top1: 0;
  --top2: 0;
  --top3: 0;
  --deg1: 20;
  --deg2: 10;


  &.viewed {
    .title {
      i {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    .text {
      opacity: 1;
    }

    .badge {
      opacity: 1;
    }
  }
}

.wrap {
  width: 100%;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;

  @include min768 {
    padding-top: 160px;
    padding-bottom: 240px;
    display: flex;
    flex-direction: row;
  }
  @include min1280 {
    padding-top: 160px;
    padding-bottom: 240px;
  }
}

.texts {
  width: 100%;
}

.title {
  color: $orange;
  display: flex;
  flex-wrap: wrap;
  
  i {
    opacity: 0;
    display: block;
    white-space: nowrap;
    transform: translate3d(0, 100%, 0.01px);
    transition: transform 0.6s cubic-bezier(0.48, 0.11, 0.23, 0.93), opacity 0.3s $ease;
    padding-right: 1rem;
  }

  span {
    display: block;
    overflow: hidden;
    padding-bottom: .4em;
    margin-bottom: -.4em;
    //width: 100%;
  }

  @include min768 {
    width: 50%;
    max-width: 450px;
  }
  @include min1024 {
    width: 100%;
    max-width: 540px;
  }
  @include min1280 {
    width: 100%;
    max-width: 700px;
  }
}

.text {
  opacity: 0;
  composes: big-body from global;
  max-width: 24ch;
  margin-top: 24px;
  margin-left: calc(100% / 4 * 1);
  transition: opacity 0.3s $ease 0.3s;

  @include min768 {
    margin-top: 32px;
    max-width: 23ch;
    margin-left: calc(100% / 10 * 1);
  }
  @include min1024 {
    margin-left: calc(100% / 8 * 1);
  }
  @include min1280 {
    max-width: 24ch;
    margin-top: 64px;
    margin-left: calc(100% / 12 * 2);
  }
}


.badges {
  margin-top: 83px;
  width: 100%;

  @include min768 {
    position: absolute;
    //right: 24px;
    right: 40px;
    width: 45%;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-top: 0;
  }
  @include min1024 {
    width: 50%;
  }
  @include min1280 {
    flex-direction: column;
    width: calc(100% / 12 * 5);
    //right: 40px;
    right: 64px;
  }
}

.image {
  position: relative;
}

.icon {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.badge {
  position: relative;
  opacity: 0;
  transition: opacity 0.3s $ease 0.8s;

  &:nth-of-type(1) {
    //-20deg
    transform: translate3d(0, var(--top1), 0.2px) rotate(var(--deg1));
    width: 130px;

    @include min768 {
      width: 160px;
    }
    @include min1024 {
      width: 180px;
    }
    @include min1280 {
      width: 220px;
    }

    .icon {
      padding-top: 100%;
    }

    .badge-title {
      color: $purple30;
      max-width: 80px;

      @include min768 {
        max-width: 100px;
      }
      @include min1280 {
        max-width: 100px;
      }
    }

    .badge-text {
      color: $purple;
      left: 115px;

      @include min480 {
        left: 125px;
      }
      @include min768 {
        left: 160px;
      }
      @include min1024 {
        left: 176px;
      }
      @include min1280 {
        left: 196px;
      }
    }

    span::after {
      background: $purple30;
    }
  }

  &:nth-of-type(2) {
    //-10deg
    transform: translate3d(0, var(--top2), 0.2px) rotate(var(--deg2));
    margin-left: auto;
    width: 100px;
    top: 20px;

    @include min768 {
      width: 130px;
      top: 50px;
    }
    @include min1024 {
      width: 150px;
      top: 60px;
    }
    @include min1280 {
      width: 170px;
      top: 80px;
    }

    .icon {
      padding-top: 131%;
    }

    .badge-title {
      color: $orange30;
      max-width: 60px;
      margin-top: 1em;
      left: 57%;

      @include min768 {
        margin-top: 1.1em;
        max-width: 80px;
      }
      @include min1280 {
        margin-top: 1em;
        max-width: 110px;
      }
    }

    .badge-text {
      text-align: right;
      color: $orange;
      right: 100px;

      @include min480 {
        right: 95px;
      }
      @include min768 {
        right: 130px;
      }
      @include min1024 {
        right: 150px;
      }
      @include min1280 {
        right: 166px;
      }
    }

    span::after {
      background: $orange30;
    }
  }

  &:nth-of-type(3) {
    //25deg
    transform: translate3d(0, var(--top3), 0.2px) rotate(var(--deg2));
    width: 120px;
    margin-top: -60px;

    @include min768 {
      margin-top: 0;
      width: 160px;
    }
    @include min1024 {
      width: 180px;
    }
    @include min1280 {
      width: 230px;
    }

    .icon {
      padding-top: 96%;
    }

    .badge-title {
      color: $green30;
      margin-top: 0.4em;
      max-width: 80px;

      @include min1280 {
        margin-top: 0.3em;
        max-width: 100px;
      }
    }

    .badge-text {
      color: $green;
      left: 110px;

      @include min480 {
        left: 115px;
      }
      @include min768 {
        left: 153px;
      }
      @include min1024 {
        left: 166px;
      }
      @include min1280 {
        left: 212px;
      }
    }

    span::after {
      background: $green30;
    }
  }
}

.badge-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  font: 700 22px/1.3 $hagrid;

  @include min768 {
    font-size: 28px;
    line-height: 1.2;
  }
  @include min1280 {
    font-size: 40px;
    line-height: 1.1;
  }
}

.badge-text {
  composes: h5 from global;
  position: absolute;
  bottom: 5px;
  line-height: 1.5;

  @include max480 {
    font-size: 14px;
  }
  @include min768 {
    bottom: 12px;
  }

  span {
    display: inline-block;
    white-space: nowrap;
    position: relative;

    i {
      max-width: 320px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      transform: translate3d(0,0,0.01px);
    }

    &::after {
      content: '';
      position: absolute;
      top: -8px;
      bottom: -8px;
      left: -20px;
      right: -20px;
      border-radius: 24px;
      z-index: -1;

      @include min768 {
        top: -12px;
        bottom: -12px;
        left: -30px;
        right: -30px;
        border-radius: 32px;
      }
      @include min1280 {
        left: -40px;
        right: -40px;
        border-radius: 42px;
      }
    }
  }
}
