@import '/styles/variables';


.main {
  position: relative;
  box-sizing: border-box;
  padding-top: 80px;

  @include min768 {
    padding-top: 100px;
  }
  @include min1280 {
    padding-top: 0;
    min-height: 250vh;
  }

  &.active {
    .title {
      p {
        opacity: 1;
      }

      span,
      .squirrel {
        opacity: 1;
      }

      .squirrel {
        transition-delay: 0.7s;
      }

      span {
        transform: translate3d(0, 0, 0);
      }
    }

    @include min1280 {
      .list a {
        &:nth-of-type(odd),
        &:nth-of-type(even) {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.sticky {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  overflow: hidden;

  @include min1280 {
    min-height: 100vh;
  }
}

.title {
  color: $orange;
  position: relative;
  composes: h1 from global;
  
  @include min1024 {
    p:last-of-type {
      margin-top: -0.6rem;
    }
  }

  p {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    opacity: 0;
    padding-bottom: .4em;
    margin-bottom: -.4em;

    @include min1280 {
      position: relative;
      z-index: 2;
    }

    &:nth-of-type(2) {
      @include min768 {
        display: flex;
        justify-content: center;
      }

      span {
        transition-delay: 0.3s;
      }
    }
  }

  span {
    opacity: 0;
    transform: translate3d(0, 120%, 0);
    transition: transform 0.6s $ease, opacity 0.3s $ease;
  }
}


.squirrel {
  margin: 2px 0 8px;
  min-width: 87px;
  max-width: 87px;
  height: 87px;
  position: relative;
  opacity: 0;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: $white;
  border-radius: 50%;
  transition: opacity 0.6s $ease 0.7s;
  pointer-events: none;
  cursor: initial;

  img {
    border-radius: 100%;
  }

  @include min768 {
    margin: 2px 12px;
    min-width: 104px;
    max-width: 104px;
    height: 104px;
  
    @media (max-height: 600px) {
      min-width: 104px * 0.75;
      max-width: 104px * 0.75;
      height: 104px * 0.75;
    }
  }
  @include min1280 {
    
    min-width: 168px;
    max-width: 168px;
    height: 168px;
  }
}

.squirrel-animation {
  animation: rotate 10s linear infinite;
  position: absolute;
  z-index: 4;
  top: -1px;
  left: -1px;
  height: 89px;
  width: 89px;

  @include min768 {
    width: 106px;
    height: 106px;
  
    @media (max-height: 600px) {
      width: 106px  * 0.75;
      height: 106px * 0.75;
    }
  }
  @include min1280 {
    width: 170px;
    height: 170px;
  }
}

.squirrel-img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;

  @include min768 {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  
    @media (max-height: 600px) {
      width: 70px * 0.75;
      height: 70px * 0.75;
    }
  }
  @include min1280 {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.vacancies {
  @include min1280 {
    --offset: 0;
    --padding: 0;
    --width: 448px;
    // --deg1: 10deg;
    //--deg2: -10deg;
  }
}

.list {
  margin-top: 48px;
  display: flex;
  flex-flow: wrap row;
  gap: 8px;

  @include min1280 {
    padding-left: var(--padding);
    transform: translate3d(var(--offset), 0, 0);
  }

  a {
    transition: transform 0.6s cubic-bezier(0.48, 0.11, 0.23, 0.93) 0.8s;

    @include min1280 {
      &:nth-of-type(odd) {
        transform: rotate(10deg);
        //transform: rotate(var(--deg1));
      }

      &:nth-of-type(even) {
        transform: rotate(-10deg);
        //transform: rotate(var(--deg2));
      }
    }
  }

  @include min768 {
    margin-top: 64px;
    
    @media(max-height: 600px) {
      margin-top: 48px;
    }

    a {
      width: calc(50% - 4px);
    }
  }
  @include min1280 {
    flex-flow: nowrap row;

    a {
      max-width: var(--width);
      min-width: var(--width);
    }
  }
}


.small {
  display: none;
  transition: opacity 0.3s $ease;

  &.hide {
    opacity: 0;
  }

  @include min768 {
    //left: 24px;
  }
  @include min1280 {
    display: block;
    position: absolute;
    bottom: 40px;
    //left: 16px;
    color: $orange;
    //left: 40px;
  }
}
