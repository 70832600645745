@import 'styles/variables';

.logo {
  width: 80px;
  height: 44px;
  display: block;
  transition: fill 0.3s ease;

  @include min768 {
    width: 87px;
    height: 47px;
  }
}
