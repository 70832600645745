@use 'sass:color';
@import '/styles/variables';

.popup {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow: auto;
  //visibility: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  color: var(--readonly-white);
  opacity: 0;
  pointer-events: none!important;
  visibility: hidden!important;
  transition: opacity 0.3s $ease;

  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    //visibility: visible;
    opacity: 1;
    pointer-events: initial!important;
    visibility: visible!important;
  }

  &.black {
    color: var(--readonly-white);

    .inner {
      background-color: var(--readonly-black);
    }

    .wrapper {
      max-width: 1096px;
    }
  }
}

/* stylelint-disable-next-line */
.selfClose {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: $orange;
}

.wrapper {
  width: calc(100% - 30px);
  max-width: 620px;
  margin: auto 0;

  @include min768 {
    max-width: 920px;
  }
}

.inner {
  width: 100%;
  padding: 40px 48px 48px;
  height: max-content;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  background-color: var(--readonly-black);
}

.close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @include min768 {
    top: 16px;
    right: 16px;
  }
  @include min1280 {
    top: 32px;
    right: 32px;
  }
}

.cross {
  transform: rotate(45deg);
}

.popup {
  &.videos {
    box-sizing: border-box;
    opacity: 0;
    visibility: visible;
    transition: 0.3s ease;
    pointer-events: none;

    & + * {
      box-sizing: border-box;
    }

    &.active {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }

    /* stylelint-disable-next-line */
    .selfClose {
      background-color: $orange10;
    }

    .wrapper {
      max-width: unset;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
      background-color: var(--readonly-black);
      box-sizing: border-box;
    }
  }

  &.error {
    .inner {
      background-color: $bg;
      border-radius: 40px;
      padding: 40px;

      @include min768 {
        border-radius: 80px;
        padding: 80px;
      }
      @include min1280 {
        border-radius: 120px;
        padding: 120px;
      }
    }
  }
}

.popup {
  &.form {
    box-sizing: border-box;

    /* stylelint-disable-next-line */
    .selfClose {
      background: $bg;
    }

    .close {
      position: fixed;
    }

    .wrapper {
      width: 100%;
      max-width: unset;
    }

    .inner {
      height: unset;
      padding: 16px 0;

      @include min768 {
        padding: 32px 0;
      }
      @include min1280 {
        padding: 60px 0 94px;
      }
    }
  }
}

.cross {
  display: block;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.85;
  }

  &:active {
  //  opacity: 0.7;
  }

  svg {
    display: block;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    stroke: $orange;
  }
}


.form {
  background: #fcdfd8;
  padding-right: 16px;
  padding-left: 16px;
  
  @include min768 {
    padding-right: 24px;
    padding-left: 24px;
  }
  @include min1280 {
    padding-right: 40px;
    padding-left: 40px;
  }
  
  .popup-wrap {
    margin: 0;
  }
}
