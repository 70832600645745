@import '/styles/variables';

.root {
  --scale: 0;
  --top1: 0;
  --top2: 0;
  --top3: 0;
  --deg1: 12deg;
  --deg2: -10deg;
  --deg3: 8deg;

  position: relative;
  transition: opacity 0.4s $ease;

  &.hide {
    .outline-circles,
    .first {
      opacity: 0 !important;
      transition-delay: 0s !important;
    }
    .first {
      visibility: hidden;
    }
    //opacity: 0;
  }
}


.bg {
  //position: fixed;
  top: 0;
  left: 0;
  right: 0;
  //height: 200vh;
  background: $orange;
  transition: opacity 0.3s linear;
  z-index: 6;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 0;


  &.active {
    pointer-events: initial;
    opacity: 1;
    transition-delay: 0.3s;
  }
}

.first {
  // height: 100vh;
  height: 1px;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  visibility: hidden;
  transition: visibility .6s linear;

  &.show {
    .title {
      opacity: 1;

      span,
      button {
        opacity: 1;
      }

      button {
        transition-delay: 0.6s;
      }

      span {
        transform: translate3d(0, 0, 0);
      }
    }

    .aside {
      opacity: 1;
    }
  }

  &.active {
    z-index: 4;
    pointer-events: initial;
    visibility: visible;

    .circles {
      opacity: 1;
    }
  }
}

.wrap {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  box-sizing: border-box;
  overflow: hidden;

  @include min768 {
    @media (max-height: 600px) {
      padding-top: 100px;
    }
  }
}

.container {
  position: relative;
  z-index: 2;
}


.title {
  color: $orange;
  position: relative;
  composes: h1 from global;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.6s linear;
  

  p {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    padding-bottom: .4em;
    margin-bottom: -.4em;
    
    @include min1280 {
      position: relative;
      z-index: 2;
    }

    &:nth-of-type(even) {
      @include min768 {
        margin-left: calc(100% / 8 * 2);
      }
      @include min1280 {
        margin-left: calc(100% / 12 * 2);
      }

      span {
        transition-delay: 0.3s;
      }
    }

    &:nth-of-type(3) {
      @include min768 {
        margin-left: calc(100% / 8 * 2);
      }
      @include min1280 {
        margin-left: calc(100% / 12 * 1);
      }
    }
  }

  span {
    opacity: 0;
    transform: translate3d(0, 120%, 0);
    transition: transform 0.6s $ease, opacity 0.3s $ease;
  
  }
}


.squirrel {
  margin: 0 0 8px;
  min-width: 87px;
  max-width: 87px;
  height: 87px;
  position: relative;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.5s $ease 0.6s;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: $white;
  border-radius: 50%;

  img {
    border-radius: 100%;
  }

  &.clickable:hover {
    .squirrel-prev {
      opacity: 1;
    }
  }

  &.not-clickable {
    pointer-events: none;
    cursor: initial;
  }

  @include min768 {
    margin: 2px 12px;
    min-width: 104px;
    max-width: 104px;
    height: 104px;

    @media (max-height: 600px) {
      min-width: 104px * 0.75;
      max-width: 104px * 0.75;
      height: 104px * 0.75;
    }
  }
  @include min1280 {
    min-width: 168px;
    max-width: 168px;
    height: 168px;
    opacity: 0;
    transition: opacity 0.5s $ease;
  
    @media (max-height: 600px) {
      min-width: 168px * 0.75;
      max-width: 168px * 0.75;
      height: 168px * 0.75;
    }
  }
}

.squirrel-animation {
  animation: rotate 10s linear infinite;
  position: absolute;
  z-index: 5;
  top: -1px;
  left: -1px;
  height: 89px;
  width: 89px;

  @include min768 {
    width: 106px;
    height: 106px;

    @media (max-height: 600px) {
      width: 106px * 0.75;
      height: 106px * 0.75;
    }
  }
  @include min1280 {
    width: 170px;
    height: 170px;
  
    @media (max-height: 600px) {
      min-width: 170px * 0.75;
      max-width: 170px * 0.75;
      height: 170px * 0.75;
    }
  }
}

.squirrel-prev {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s $ease;
  transform: translateZ(0.001px);
  width: 100%;
  height: 100%;
}

.squirrel-img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;

  @include min768 {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;

    @media (max-height: 600px) {
      width: 70px * 0.75;
      height: 70px * 0.75;
      margin-bottom: 9px;
    }
  }
  @include min1280 {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  
    @media (max-height: 600px) {
      margin-bottom: 20px * 0.75;
      min-width: 100px * 0.75;
      max-width: 100px * 0.75;
      height: 100px * 0.75;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg) translate3d(0, 0, 0.03px);
  }
}

.aside {
  opacity: 0;
  transition: opacity 0.6s linear 0.6s;

  @include min768 {
    margin-left: 50%;
  }
  @include min1280 {
    margin-left: calc(100% / 12 * 7);
  }
}

.hand {
  display: block;
  margin-top: 24px;
  max-width: 21ch;

  @include min768 {
    margin-top: 32px;

    @media (max-height: 600px) {
      margin-top: 24px;
    }
  }
  @include min1024 {
    padding-left: 1rem;
    padding-bottom: 1rem;
    margin-left: -1rem;
    margin-bottom: -1rem;
  }
  @include min1280 {
    margin-top: 64px;
  
  
    @media (max-height: 600px) {
      margin-top: 24px;
    }
  }
}

.btn {
  margin-top: 24px;
  display: inline-block;

  @include min768 {
    margin-top: 32px;

    @media (max-height: 600px) {
      margin-top: 16px;
    }
  }
  @include min1280 {
    margin-top: 40px;
  }
}

.small {
  position: absolute;
  bottom: 40px;
  color: $orange;
  transition: opacity 0.6s $ease;
  //opacity: 0;
}

.circles {
  width: 200vh;
  height: 200vh;
  top: var(--y);
  left: var(--x);
  position: absolute;
  border-radius: 50%;
  opacity: 1;
  background: $orange;
  transition: transform 0.4s cubic-bezier(0.36, 0.27, 0.35, 1.25);
  transform: translate3d(-50%, -50%, 0) scale(var(--scale));
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  &::before {
    width: 33vw;
    height: 33vw;
    border: 5vw solid #f05d34;
  }

  &::after {
    width: 24vw;
    height: 24vw;
    border: 5vw solid #f37e5d;
  }
}

/////// second screen
//style={{transform: `rotate(${Math.round(0.5 + 14 * Math.random() * (Math.random() > 0.5 ? -1 : 1))}deg)`}}

.second {
  color: $white;
  position: relative;
  min-height: 260vh;
  padding-top: 60px;
  //padding-bottom: 60px;
  //padding-bottom: 100px;
  pointer-events: none;
  z-index: -1;

  @include min768 {
    padding-bottom: 120px;
  }
  @include min1280 {
    @media (max-height: 800px) {
      padding-bottom: 120px;
    }
  }

  &.active {
    z-index: 6;
    pointer-events: initial;

    .more,
    .parallax {
      transition: opacity 0.4s cubic-bezier(0.48, 0.1, 0.34, 0.96) 0.4s;
      opacity: 1;
    }

    .outline-circles {
      transition: opacity 0.4s linear 0.6s;
      opacity: 0.16;
    }

    .title-second span {
      opacity: 1;
      transition-delay: 0.2s;

      &:nth-of-type(odd),
      &:nth-of-type(even) {
        transform: translate3d(0, 0, 0);
        transition-delay: 0.2s;
      }
    }
  }
}

.sticky {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.outline-circles {
  top: var(--y);
  left: var(--x);
  transform: translate3d(-50%, -50%, 0);
  width: 85vw;
  height: 85vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;

  &,
  &::before,
  &::after,
  div {
    position: absolute;
    border-radius: 50%;
    border: 1px solid $white;
  }

  &::before,
  &::after {
    content: '';
  }

  &::before {
    width: 70%;
    height: 70%;
  }

  &::after {
    width: 40%;
    height: 40%;
  }

  div {
    width: 12%;
    height: 12%;
  }
}

.title-second {
  composes: big-h1 from global;
  padding-bottom: .4em;
  margin-bottom: -.4em;

  span {
    display: block;
    position: relative;
    opacity: 0;
    transition: transform 0.6s $ease, opacity 0.3s $ease;
    z-index: 6;

    &:nth-of-type(odd) {
      transform: translate3d(-50%, 0, 0);
    }

    &:nth-of-type(even) {
      transform: translate3d(50%, 0, 0);
    }


    @include max768 {
      &:nth-of-type(even) {
        margin-left: 25%;
      }
    }
    @include pointsTablet {
      &:nth-of-type(2) {
        margin-left: calc(100% / 8 * 2);
      }

      &:nth-of-type(4) {
        margin-left: calc(100% / 8 * 1);
      }
    }
    @include min1280 {
      &:nth-of-type(2) {
        margin-left: calc(100% / 12 * 2);
      }

      &:nth-of-type(3) {
        white-space: nowrap;
      }
    }
  }
}


.more {
  opacity: 0;
}


.parallax {
  position: absolute;
  z-index: 5;
  bottom: 0;
  opacity: 0;

  &,
  .image {
    width: 140px;
    height: 193px;

    @include min768 {
      width: 174px;
      height: 240px;
    }
    @include min1024 {
      width: 334px * 0.8;
      height: 460px * 0.8;
    }
    @include min1280 {
      width: 334px;
      height: 460px;
    }
  }

  img {
    object-fit: cover;
  }

  &:nth-of-type(3n) {
    transform: translate3d(0, var(--top3), 0);
    transition: transform 0.4s cubic-bezier(0.48, 0.1, 0.34, 0.96);

    .image {
      transform: rotate(var(--deg1));
    }
  }

  &:nth-of-type(3n + 1) {
    transform: translate3d(0, var(--top1), 0);
    transition: transform 0.3s cubic-bezier(0.48, 0.1, 0.34, 0.96);

    .image {
      transform: rotate(var(--deg2));
    }
  }

  &:nth-of-type(3n + 2) {
    transform: translate3d(0, var(--top2), 0);
    transition: transform 0.3s cubic-bezier(0.48, 0.1, 0.34, 0.96);

    .image {
      transform: rotate(var(--deg3));
    }
  }

  &:nth-of-type(1) {
    left: 36%;
    top: 120%;

    @include min1280 {
      top: 600px;
    }
  }

  &:nth-of-type(2) {
    right: 5%;
    top: 170%;

    @include min1280 {
      top: 900px;
    }
  }

  &:nth-of-type(3) {
    left: 5%;
    top: 120%;

    @include min1280 {
      top: 1200px;
    }
  }

  &:nth-of-type(4) {
    left: 20%;
    top: calc(100vh + 160%);

    @include min768 {
      left: 52%;
    }
  }

  &:nth-of-type(5) {
    right: -2%;
    top: calc(100vh + 140%);
  }

  &:nth-of-type(6) {
    left: -5%;
    top: calc(100vh + 100%);
  }
}

.image {
  position: relative;
  border-radius: 56px;
  overflow: hidden;
  display: flex;

  @include min768 {
    border-radius: 64px;
  }
  @include min1280 {
    border-radius: 120px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border: 4px solid $white;
    border-radius: 56px;

    @include min768 {
      border-width: 8px;
      border-radius: 64px;
    }
    @include min1280 {
      border-radius: 120px;
      border-width: 16px;
    }
  }
}
