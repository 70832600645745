@import '/styles/variables';

.main {
  padding: 128px 0;

  @include min768 {
    padding: 160px 0;
  }
  @include min1280 {
    padding: 168px 0;
  }
}

.title {
  text-align: center;
  color: $orange;
  margin-bottom: 32px;

  @include min768 {
    margin-bottom: 64px;
  }
  @include min1280 {
    margin-bottom: 80px;
  }
}

.filters {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  flex-flow: wrap column;

  @include min768 {
    align-items: center;
    flex-flow: nowrap row;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
    gap: 24px;
  }
  @include min1280 {
    gap: 48px;
    width: calc(100% / 12 * 10);
    margin-bottom: 64px;
  }
}

.select {
  display: flex;
  gap: 8px;
  width: 100%;
  flex-flow: wrap column;

  @include min768 {
    flex-flow: nowrap row;
  }
  @include max768 {
    & > div:first-of-type {
      z-index: 5;
    }
  }
}

.active {
  background: $black;
}

.sort {
  display: none;

  @include min768 {
    display: block;
  }
}

.text {
  text-transform: uppercase;
  font: 500 12px/1 $nobile;
  white-space: nowrap;
  letter-spacing: 0.06em;
}

.reset {
  color: $orange;
  position: relative;
  //align-self: flex-start;
  transition: opacity 0.3s $ease, color 0.3s $ease;
  padding: 8px 8px 8px 20px;
  margin-left: auto;
  margin-top: 8px;

  @include min768 {
    margin: 0;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    height: 13px;
    width: 2px;
    background: currentcolor;
    left: 5px;
    top: 6px;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(135deg);
  }

  &:hover {
    color: $black;
  }

  &.hidden {
    opacity: 0!important;
    pointer-events: none;
  }
}


.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @include min768 {
    a {
      width: calc(50% - 4px);
    }
  }
  @include min1280 {
    a {
      width: calc(100% / 3 - 6px);
    }
  }
}

.no-vacancy {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  @include min768 {
    align-items: center;
    gap: 32px;
    flex-direction: row;
  }

  h4 {
    margin-bottom: 8px;
  }
  
  .img {
    max-width: 150px;
    max-height: 150px;
  }
}
