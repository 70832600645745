@import '/styles/variables';

.card {
  position: relative;
  z-index: 2;
  display: flex;
  //max-width: 288px;
  min-height: 168px;
  width: 100%;
  flex-direction: column;

  @include min768 {
    min-height: 220px;

    @media (max-height: 600px) {
      min-height: 220px * 0.75;
    }
  }
  @include min1280 {
    min-height: 280px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: $orange;
    z-index: -1;
    border-radius: 64px;

    @include min1280 {
      border-radius: 104px;
    }
  }

  &:hover {
    .wrap {
      transform: translate3d(-12px, -12px, 0);

      &::before {
        opacity: 1;
      }
    }
  }
}

.wrap {
  flex: 1 0 auto;
  background: $white;
  border-radius: 64px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 24px 32px;
  box-sizing: border-box;
  transition: transform 0.3s $ease;
  width: 100%;
  height: 100%;

  @include min768 {
    padding: 32px;
  }
  @include min1280 {
    padding: 48px;
    border-radius: 104px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid currentcolor;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s $ease;
    border-radius: 64px;

    @include min1280 {
      border-radius: 104px;
    }
  }
}

.title {
  margin-bottom: 24px;
  //когда строк много, а ограничение по символам не спасет
  //overflow: hidden;
  //display: -webkit-box;
  //-webkit-line-clamp: 3;
  //-webkit-box-orient: vertical;
  overflow: visible;
}

.tags {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.tag {
  border: 1px solid $orange30;
  composes: small from global;
  padding: 2px 16px;
  border-radius: 32px;
  min-height: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-bottom: 4px;
}

.icon {
  position: relative;
  display: block;
  width: 9px;
  height: 9px;
  border-top: 2px solid $white;
  border-right: 2px solid $white;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    background: $white;
    transform: rotate(45deg);
    top: -3px;
    left: 3px;
    width: 2px;
    height: 11px;
  }
}

.more {
  color: $white;

  .wrap {
    background: $orange;

    &::before {
      border-color: $black;
    }
  }

  &::after {
    background: $black;
  }

  .tag {
    border-color: $white;
  }
}

.orange {
  color: $orange;

  &::after {
    background: $orange;
  }

  .tag {
    border-color: $orange30;
  }
}

.yellow {
  color: $yellow;

  &::after {
    background: $yellow;
  }

  .tag {
    border-color: $yellow30;
  }
}

.purple {
  color: $purple;

  &::after {
    background: $purple;
  }

  .tag {
    border-color: $purple30;
  }
}

.green {
  color: $green;

  &::after {
    background: $green;
  }

  .tag {
    border-color: $green30;
  }
}

.black {
  color: $black;

  &::after {
    background: $black;
  }

  .tag {
    border-color: $black30;
  }
}
