@import '../../../../../styles/variables';

.menuWrap {
  position: fixed;
  left: 0;
  right: 0;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  padding: 104px 0 116px;
  background-color: $white;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 0.1s ease;
  box-sizing: border-box;

  @include min768 {
    padding: 120px 0 56px;
  }

  &.opened {
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.3s ease;
  }
}


.menu {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  padding: 0 22px;
  height: calc(100vh - 230px);

  @include min768 {
    height: calc(100vh - 176px);
    
    @media(max-height: 600px) {
      column-gap: 24px;
      columns: 2;
      
      li {
        break-inside: avoid;
      }
    }
  }
}

.wrap {
  background-color: $white;
}
