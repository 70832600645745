@import '/styles/variables';

.block {
  max-width: 1466px;
  width: 100%;
  margin: 0 auto 80px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }


  @include min768 {
    margin-bottom: 120px;
  }
  @include min1280 {
    margin-bottom: 200px;
  }


  p {
    text-align: center;
    color: $orange;
    max-width: 400px;
    margin: 16px auto 0;

    @include min768 {
      max-width: 75%;
    }
  }

  &.success {
    margin-bottom: 0;

    .title {
      text-align: center;
      max-width: 400px;
      margin: 0 auto;

      @include min768 {
        max-width: 75%;
      }
    }
  }

  &.vacancy {
    margin-bottom: 0;
  }

  &.getintouch {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 0;

    @include min768 {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    @include min1280 {
      padding-top: 60px;
      padding-bottom: 94px;
    }
  }
}

.wrap {
  background-color: $pig;
  padding: 48px 16px 64px;
  border-radius: 64px;

  @include min768 {
    padding: 80px 90px 84px;
    border-radius: 104px;
  }
  @include min1280 {
    padding: 120px;
    border-radius: 200px;
  }
}

.title {
  text-align: center;
  color: $orange;
  margin-bottom: 32px;

  @include min768 {
    text-align: left;
    margin-bottom: 48px;
  }
  @include min1280 {
    margin-bottom: 64px;
  }
}


.popup {
  .wrap {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    background: initial;

    @include max1280 {
      padding: 0;
    }
  }
}

