@import '/styles/variables';

.main {
  position: relative;
  padding-top: 80px;
  box-sizing: border-box;

  --offset: 0;
  --duration: 0;
  --slider: 100%;
  --deg: 10deg;

  @include min768 {
    padding-top: 128px;
  }
  @include min1280 {
    min-height: 230vh;
    padding-bottom: 40px;
    margin-bottom: -200px;

    @include maxHeight {
      margin-bottom: -100px;
      padding-top: 80px;
    }
  }

  &.visible {
    @include min1280 {
      .slider {
        pointer-events: initial;

        [class~='swiper-slide'] {
          opacity: 1;
          visibility: visible;
        }
      }

      .more {
        opacity: 1;
      }

      .news a {
        &:nth-of-type(3),
        &:nth-of-type(1) {
          z-index: 1;
          transform: translate3d(0, 0, 0) rotate(0);
          transition-duration: 0.4s;
        }

        &:nth-of-type(2) {
          transition: transform 0.2s cubic-bezier(0.48, 0.11, 0.23, 0.93);
          transform: rotate(0);
          //z-index: 1;
        }

        &:hover {
          z-index: 2;
        }
      }
    }
  }
}

.wrap {
  @include min1280 {
    transform: translate3d(0, var(--slider), 0);
  }
}

.news {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  justify-content: center;

  a {
    position: relative;

    &:nth-of-type(2) {
      transform: rotate(var(--deg));
      z-index: 2;
    }

    &:nth-of-type(1) {
      transition: transform 0.1s $ease;
      z-index: 1;
      transform: translate3d(100%, 0, 0) rotate(var(--deg));
    }

    &:nth-of-type(3) {
      transition: transform 0.1s $ease;
      z-index: 1;
      transform: translate3d(-100%, 0, 0) rotate(var(--deg));
    }
  }
}

.sticky {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  overflow: hidden;

  @include min1280 {
    min-height: 680px;
    height: 100vh;
  }
}

.example,
.lines {
  composes: big-h1 from global;
  composes: outline-text from global;

  span {
    padding-right: 0.8em;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 0.34em;
      width: 0.11em;
      height: 0.11em;
      border: 1px solid $orange;
      border-radius: 50%;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      box-sizing: border-box;
    }
  }
}

.example {
  visibility: hidden;
  position: absolute;
}

.lines {
  display: flex;
  flex-direction: column;
  gap: 0.17em;
  position: relative;
  margin-bottom: 80px;
  opacity: var(--opacity);
  //transition: opacity 0.3s $ease;

  @include min768 {
    margin-bottom: 120px;
  }
  @include min1280 {
    margin-bottom: 0;
    position: absolute;
    top: 50vh;
    left: 0;
    transform: translate3d(0, -50%, 0);
  }

  div {
    position: relative;
    white-space: nowrap;


    &:nth-of-type(even) {
      animation: forward var(--duration) linear infinite;

      @include min1280 {
        left: 0;
        //transform: translate3d(var(--offset1), 0, 0);
      }
      @include max1280 {
        left: 40%;
        //left: calc((1) * var(--offset1));
        // animation: forward var(--duration) linear infinite;
      }
    }

    &:nth-of-type(odd) {
      animation: back var(--duration) linear infinite;

      @include min1280 {
        left: -50%;
        //transform: translate3d(var(--offset2), 0, 0);
      }
      @include max1280 {
        left: -20%;
        //left: calc((0.5) * var(--offset2));
        //animation: back var(--duration)  linear infinite;
      }
    }
  }
}


@keyframes forward {
  100% {
    //transform: translate3d(var(--offset1), 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
@keyframes back {
  100% {
    //transform: translate3d(var(--offset2), 0, 0);
    transform: translate3d(50%, 0, 0);
  }
}


.slider {
  overflow: visible !important;
  transition: opacity 0.6s $ease;
  max-width: 400px;

  [class~='swiper-slide'] {
    z-index: 1;

    &:hover {
      z-index: 2;
    }
  }

  @include min1280 {
    pointer-events: none;

    [class~='swiper-slide'] {
      //opacity: 0;
      //visibility: hidden;
      //transition: opacity 0.6s $ease, visibility 0.6s, transform 0.6s $ease;
    }

    [class~='swiper-slide-active'] {
      opacity: 1;
      visibility: visible;
      transform: rotate(var(--deg));
    }
  }
}

.news-item {
  @include min1280 {
    max-width: 450px;
  }
}

.more {
  @include min1280 {
    opacity: 0;
    transition: opacity 0.3s $ease;

    &.active {
      opacity: 1;
    }
  }
}
