@import 'styles/variables';

.block {
  composes: article-wrapper from global;
  margin-bottom: 16px;
}

.title {
  composes: small-links from global;
  text-transform: uppercase;
  margin-bottom: 8px;
}


.share {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.icon {
  border: 1px solid $orange;
  background-color: transparent;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  border-radius: 50%;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include min1280 {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
  }

  &:hover {
    background-color: $orange;
    border-color: $orange;

    svg {
      fill: $white;
    }
  }

  svg {
    width: 32px;
    height: 32px;
    display: block;
    fill: $orange;
    transition: fill 0.3s ease;
  }
}
