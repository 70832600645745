@import '../../../../../styles/variables';

.block {
  display: inline-flex;
  flex-direction: column;

  @include min768 {
    //display: flex;
    flex-direction: row;
    display: flex;
    align-items: flex-end;
  }
}

.copyright {
  order: 2;
  composes: small from global;

  @include min768 {
    order: 1;
  }
}

.links {
  display: flex;
  flex-direction: column;
  
  
  @include max768 {
    a {
      font-size: 16px;
      line-height: 1.4;
      padding: 2px 0;
    }
  }
  
  
  @include min768 {
    order: 2;
    margin-left: 56px;
  }
}

.privacy,
.terms {
  composes: small from global;

  &:nth-last-of-type(1) {
    margin-bottom: 24px;

    @include min768 {
      margin-bottom: 0;
    }
  }


  @include min768 {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}
