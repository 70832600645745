@import '/styles/variables';

.block {
  padding-top: 128px;
  padding-bottom: 48px;

  @include min768 {
    padding-top: 160px;
    padding-bottom: 64px;
  }
  @include min1280 {
    padding-top: 168px;
    padding-bottom: 72px;
  }
}

.wrap {
  @include min768 {
    display: flex;
    flex-direction: column;
  }
}

.title {
  color: $orange;
  padding-bottom: .4em;
  margin-bottom: -.4em;

  @include min768 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.squirrel {
  margin: 0 0 8px;
  min-width: 87px;
  max-width: 87px;
  height: 87px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: $white;
  border-radius: 50%;

  img {
    border-radius: 100%;
  }

  @include min768 {
    margin: 2px 12px;
    min-width: 104px;
    max-width: 104px;
    height: 104px;

    @media (max-height: 600px) {
      min-width: 104px * 0.75;
      max-width: 104px * 0.75;
      height: 104px * 0.75;
    }
  }
  @include min1280 {
    min-width: 168px;
    max-width: 168px;
    height: 168px;
  }
}

.squirrel-animation {
  animation: rotate 10s linear infinite;
  position: absolute;
  z-index: 4;
  top: -1px;
  left: -1px;
  height: 89px;
  width: 89px;

  @include min768 {
    width: 106px;
    height: 106px;

    @media (max-height: 600px) {
      width: 106px * 0.75;
      height: 106px * 0.75;
    }
  }
  @include min1280 {
    width: 170px;
    height: 170px;
  }
}

.squirrel-img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;

  @include min768 {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;

    @media (max-height: 600px) {
      width: 70px * 0.75;
      height: 70px * 0.75;
    }
  }
  @include min1280 {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.sign {
  margin-top: 24px;

  @include min768 {
    display: inline;
    margin-top: 32px;
    margin-left: 50%;
    
    @media (max-height: 600px) {
      margin-top: 24px;
    }
  }
  @include min1280 {
    margin-top: 40px;
    margin-left: calc(100% / 12 * 8 - 80px);
  }

  a {
    color: $black;
    transition: color 0.3s $ease;

    &:hover {
      color: $orange;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
