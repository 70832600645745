@import 'styles/variables';

.li {
  position: relative;

  &.hovered {
    .link {
      background-color: $orange;
      transform: translateX(-5px) translateY(-5px);
      box-shadow: 5px 5px 0 0 $black;
      color: $white;
    }
  }

  &.whited {
    .link {
      color: $white;
      border-color: $white;
      background-color: transparent;
    }

    &:hover,
    &.hovered {
      .link {
        color: $orange;
        background-color: $white;
      }
    }
  }
}

.item {
  position: relative;

  @include center-center;

  &:hover {
    .link {
      background-color: $orange;
      transform: translateX(-5px) translateY(-5px);
      box-shadow: 5px 5px 0 0 $black;
      color: $white;
    }
  }
}

.link {
  padding: 8px 16px 6px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: $nobile;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.06px;
  font-weight: 500;
  color: inherit;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  box-shadow: 0 0 0 0 $black;
  border: 1px solid $orange;
  border-radius: 32px;
  
  &::after {
    display: none;
  }
}
