@import 'styles/variables';

.wrapper {
  margin-top: 8px;
  display: block;
  position: relative;
  cursor: text;
  color: $orange;
  border-radius: 32px;
  transition: background-color 0.3s $ease;

  @include min768 {
    margin-top: 16px;
    border-radius: 40px;
  }
  @include min1280 {
    border-radius: 48px;
    margin-top: 24px;
  }

  &:nth-child(1) {
    margin-top: 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid $orange;
    pointer-events: none;
    border-radius: 32px;
    transition: border-color 0.3s $ease;

    @include min768 {
      border-radius: 40px;
    }
    @include min1280 {
      border-radius: 48px;
    }
  }

  &::after {
    border-width: 2px;
    opacity: 0;
    transition: border-color 0.3s $ease, opacity 0.3s $ease;
  }

  &.textarea,
  &.textarea::before,
  &.textarea::after {
    border-radius: 24px;

    @include min768 {
      border-radius: 32px;
    }
    @include min1280 {
      border-radius: 40px;
    }
  }

  input,
  textarea {
    width: 100%;
    display: block;
    outline: none;
    font: inherit;
    color: inherit;
    box-sizing: border-box;
    transition: color 0.3s $ease;
  }

  input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::-webkit-contacts-auto-fill-button,
    &::-webkit-inner-spin-button {
      display: none !important;
    }
  }

  //input:-internal-autofill-selected {
  //  background-color: transparent !important;
  //}
  //
  //input:-webkit-autofill {
  //  -webkit-text-fill-color: transparent !important;
  //}
  //
  //input:-webkit-autofill,
  //input:-webkit-autofill:hover,
  //input:-webkit-autofill:focus,
  //input:-webkit-autofill:active {
  //  -webkit-box-shadow: 0 0 0 30px white inset !important;
  //}


  input {
    padding: 11px 44px 10px 24px;
    height: 42px;
    box-sizing: border-box;

    @include min768 {
      border-radius: 40px;
      padding: 14px 52px 13px 32px;
      height: 48px;
    }
    @include min1024 {
      height: 52px;
    }
    @include min1280 {
      height: 62px;
      border-radius: 48px;
      padding: 19px 52px 18px 32px;
    }
    
    &[name="phone"] {
      padding-left: 36px;
  
      @include min768 {
        padding-left: 48px;
      }
    }


    &.disabled &label {
      opacity: 0.2;
    }
  }
  
  &.textarea {
    padding: 0 12px 0 24px;
    box-sizing: border-box;
    overflow: hidden;
   
    @include min768 {
      padding: 0 18px 0 32px;
    }
    @include min1280 {
      padding: 0 18px 0 32px;
    }
  }

  textarea {
    min-height: calc(4em + 24px);
    resize: none;
    overflow: auto;
    border-radius: 0!important;
    padding: 11px 12px 10px 0;
    
    @include min768 {
      border-radius: 32px;
      padding: 14px 14px 13px 0;
      min-height: calc(5em + 24px);
    }
    @include min1024 {
      min-height: 120px;
    }
    @include min1280 {
      padding: 19px 30px 18px 0;
      height: 148px;
    }
  
  
    &::-webkit-scrollbar {
      width: 2px;
      background-color: $orange30;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $orange;
      border-radius: 8px;
    }
  
  }

  &:global(.not-empty) {
    .placeholder {
      opacity: 0;
      transition: opacity 0.15s $ease;
    }
  
    [name="phone"] + .plus {
      opacity: 1;
    }
  }


  &:global(.error) {
    background: transparentize($purple, 0.9);
    color: $purple;

    &::before {
      border-color: $purple;
    }

    .notice {
      opacity: 1;
    }

    .warning {
      opacity: 1;
    }
  }

  &:global(.focus) {
    background-color: transparent;
    color: $orange;

    &::after {
      opacity: 1;
    }

    .placeholder {
      opacity: 0;
    }

    .notice {
      opacity: 0;
    }

    .warning {
      opacity: 0;
    }
    
    [name="phone"] + .plus {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.placeholder,
.notice {
  pointer-events: none;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  font: inherit;
  color: inherit;
  padding: 11px 24px 10px;
  transition: opacity 0.1s $ease, color 0.15s $ease;

  @include min768 {
    padding: 14px 32px 13px;
  }
  @include min1280 {
    padding: 19px 32px 18px;
  }
}

.plus {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: .5em;
  height: 1em;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity .2s $ease;
  
  @include min768 {
    left: 32px;
  }
  
  &::before {
    content: '+';
  }
}

.notice {
  position: absolute;
  top: -28px;
  right: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: $white;
  background-color: $purple;
  padding: 8px 16px;
  border-radius: 8px;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s $ease;

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 27px;
    bottom: -5px;
    background-color: $purple;
    transform: rotate(45deg);
    border-radius: 4px;
    z-index: -1;
  }
}

.warning {
  width: 24px;
  height: 25px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s $ease;

  svg {
    display: block;
    width: 24px;
    height: 25px;
  }
}
