@import '../../../../../../styles/variables';

.block {
  composes: article-wrapper from global;
  margin-bottom: 56px;
  margin-top: 56px;

  @include min768 {
    margin-bottom: 80px;
    margin-top: 80px;
  }
  @include min1280 {
    margin-bottom: 120px;
    margin-top: 120px;
  }

  h5 {
    color: $orange;
    text-align: center;
  }
}

.wrap {
  background-color: $pig;
  border-radius: 48px;
  padding: 64px 32px;
  position: relative;

  @include min768 {
    border-radius: 80px;
    padding: 64px calc(100% / 8);
  }
  @include min1280 {
    border-radius: 120px;
    padding: 120px calc(100% / 12);
  }
  
  
  &.large {
    padding-top: 64px;
    padding-bottom: 120px;
    
    @include min1280 {
      padding-top: 120px;
      padding-bottom: 140px;
    }
  }
}

.icon {
  position: absolute;
  width: 84px;
  height: 80px;
  top: -15px;
  left: 6px;

  @include center-center;
  @include min768 {
    width: 106px;
    height: 104px;
    left: 12px;
    top: -40px;
  }
  @include min1280 {
    width: 126px;
    height: 124px;
    left: 40px;
    top: -50px;
  }
}

.outer {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
}

.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 54%;
  z-index: 2;

  @include center-center;
  @include min1280 {
    top: calc(50% - 4px);
    left: 50%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
}

.author {
  color: $orange;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;

  @include min1280 {
    bottom: 48px;
  }
}

.position {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 700;

  @include min1280 {
    font-size: 18px;
  }
}

.name {
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.4;

  @include min1280 {
    font-size: 14px;
  }
}
