@import 'styles/variables';

.drop {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  padding-top: 17px;
  pointer-events: none;
  opacity: 0;

  &.active {
    pointer-events: auto;
    opacity: 1;
  }
}

.wrap {
  padding: 12px 16px;
  background-color: $orange;
  border-radius: 16px;
  box-shadow: 5px 5px 0 0 $black;
  display: flex;
  flex-direction: column;

  a {
    display: inline-block;
    white-space: nowrap;
    font-size: 18px;
    line-height: 1.4em;
    letter-spacing: -0.02em;
    font-family: $ibm;
    color: $white;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}
