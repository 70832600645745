@import 'STYLES/variables';


.wrap {
  position: relative;
}

.slider {
  overflow: visible !important;

  [class*='swiper-slide'] {
    height: initial;
    display: flex;

    & > * {
      width: 100%;
    }
  }
}

.block {
  margin-top: 80px;
  overflow: hidden;

  @include min768 {
    margin-top: 120px;
  }
  @include min1280 {
    margin-top: 200px;
  }
}

.nav {
  display: flex;
  justify-content: center;
  gap: 8px;

  .next svg {
    transform: rotate(180deg);
  }
}

.button {
  z-index: 10;

  span {
    display: block;
  }

  &.large::before {
    content: '';
    position: absolute;
    top: 0;
    width: 50vw;
    height: calc(100% - 80px);

    @include min768 {
      height: calc(100% - 98px);
    }
    @include min1280 {
      height: calc(100% - 130px);
    }
  }

  &.prev::before {
    background: transparent;
    left: 50%;
    transform: translate3d(-50vw, 0, 0);
  }

  &.next::before {
    background: transparent;
    right: 50%;
    transform: translate3d(50vw, 0, 0);
  }
}

