@import '/styles/variables';

.title {
  font-family: $nobile;
  font-size: 12px;
  line-height: 1.3em;
  letter-spacing: 0.06em;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;

}

.link {
  font-family: $hagrid;
  font-size: 18px;
  line-height: 1.4em;
  font-weight: 500;
  text-transform: uppercase;
  color: $orange;

  @include min1280 {
    font-size: 24px;
  }

  &:hover {
    text-decoration: underline;
  }
}
