@import 'styles/variables';

.footer {
  //margin-top: 140px;
  position: relative;
}

.wrap {
  padding-top: 88px;
  padding-bottom: 16px;

  @include min768 {
    padding-top: 128px;
  }
  @include min1280 {
    padding-top: 200px;

    @include maxHeight {
      padding-top: 140px;
    }
  }
}

.navigation {
  margin-bottom: 40px;

  @include min768 {
    margin-bottom: 0;
  }
}

.contacts {
  margin-top: 40px;
  margin-bottom: 24px;

  @include min768 {
    margin-top: 0;
    margin-bottom: 16px;
  }
  @include min1280 {
    margin-bottom: 0;
  }
}

.social {
  margin-bottom: 48px;
  margin-top: 24px;
}

.primitive {
  margin-top: 48px;
  margin-bottom: 24px;

  @include min768 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.copyright {
  margin-bottom: 10px;

  @include min768 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.rc {
  margin-top: 10px;

  @include min768 {
    margin-left: auto;
  }
}

.tabletrow {
  display: flex;
  align-items: flex-start;

  &:nth-last-of-type(1) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  &:nth-of-type(1) {
    margin-bottom: 65px;
  }

  &:nth-of-type(2) {
    display: flex;
  }
}

.tabletcolumn {
  width: calc(50% - 20px);

  &:nth-of-type(1) {
    margin-right: 20px;
  }

  &:nth-of-type(2) {
    margin-left: 20px;
    display: flex;
    align-items: flex-end;
  }
}

.desktoprow {
  display: flex;
  flex-direction: row;

  &:not(:nth-last-of-type(1)) {
    margin-bottom: 116px;
  }

  &:nth-last-of-type(1) {
    align-items: flex-end;
  }

  .desktopcolumn {
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
    }

    &:nth-last-of-type(1) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transform: translateY(4px);
    }
  }
}

.desktopcolumn {
  gap: 0 24px;
  
  &:nth-of-type(1) {
    width: calc(31% - 12px);
    margin-right: 9px;
    align-self: flex-end;
  }

  &:nth-of-type(2) {
    width: calc(27% - 12px);
    margin-left: 9px;
    margin-right: 9px;
  }

  &:nth-of-type(3) {
    width: calc(42% - 12px);
    margin-left: 9px;
  }
}

.links {
  display: flex;
  flex-flow: row wrap;
  gap: 0 24px;
  margin-bottom: 2px;
  
  a {
    display: inline-block;
    padding: 2px 0;
    
    &:after {
      display: none;
    }
    
    &:nth-of-type(1),
    &:nth-of-type(3) {
      width: calc(35% - 12px);
    }
    &:nth-of-type(2),
    &:nth-of-type(4) {
      width: calc(65% - 12px);
    }
  }
}


.privacy,
.terms {
  composes: small from global;
}
