@import '/styles/variables';

.wrapper {
  margin-top: 8px;
  display: block;
  position: relative;
  cursor: text;
  pointer-events: none;
  color: $orange;
  margin-bottom: 28px;

  @include min768 {
    margin-top: 16px;
  }
  @include min1024 {
    margin-top: 16px;
  }
  @include min1280 {
    margin-top: 24px;
  }

  &:nth-child(1) {
    margin-top: 0;
  }

  input {
    display: none;
  }

  textarea {
    min-height: calc(1.3em + 14px);
    resize: none;
  }

  .notice {
    text-align: center;
  }

  .button {
    pointer-events: auto;
    cursor: pointer;
  }

  &.fill {
    .button {
      border: 1px solid $orange;
      background: none;
    }
  }

  &:global(.not-empty) {
    .placeholder {
      opacity: 0 !important;
      transition: opacity 0.3s $ease;
    }
  }

  &:global(.error) {
    .button {
      background: transparentize($purple, 0.9);
      color: $purple;
      border-color: $purple;
    }

    &::before {
      border-color: $purple;
    }

    .icon {
      &::before,
      &::after {
        background-color: $purple;
      }
    }

    .notice {
      opacity: 1;
    }

    .warning {
      opacity: 1;
    }

    .sign {
      color: $purple;
    }
  }
}

.additional {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  pointer-events: none;
  padding-top: 8px;
}

.button {
  display: flex;
  align-items: center;
  padding: 11px 24px 10px;
  border-radius: 32px;
  border: 1px dashed $orange;
  position: relative;
  max-width: 100%;
  box-sizing: border-box;

  @include min768 {
    border-radius: 40px;
    padding: 14px 32px;
  }
  @include min1280 {
    border-radius: 48px;
    padding: 18px 32px 17px;
  }
}

.icon {
  display: block;
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  position: relative;
  margin-right: 12px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 2px;
    background-color: $orange;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    width: 12px;
    height: 2px;
    background-color: $orange;
  }
}

.label {
  font: inherit;
}

.sign {
  display: block;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
  line-height: 1.4;
  transition: color 0.3s $ease;
  opacity: 0.6;

  @include min768 {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.right {
  display: block;
}

.names {
  display: block;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clear {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  cursor: pointer;
  font-size: 0;
  position: relative;
  margin-right: 12px;
  transform: rotate(45deg);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 2px;
    background-color: $orange;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    width: 12px;
    height: 2px;
    background-color: $orange;
  }
}

.notice {
  position: absolute;
  top: calc(50% - 60px);
  right: -8px;
  transform: translate(12px, -50%);
  min-width: 240px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: $white;
  background-color: $purple;
  padding: 8px 16px;
  border-radius: 8px;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s $ease;

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 27px;
    bottom: -5px;
    background-color: $purple;
    transform: rotate(45deg);
    border-radius: 4px;
    z-index: -1;
  }
}

.warning {
  width: 24px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s $ease;

  svg {
    display: block;
    width: 24px;
    height: 25px;
  }
}
