@import '/styles/variables';

.heading {
  padding-top: 128px;
  margin: 0 auto 48px;
  box-sizing: border-box;
  width: 100%;

  @include min768 {
    padding-top: 161px;
    margin-bottom: 64px;
  }
  @include min1280 {
    padding-top: 168px;
    margin-bottom: 80px;
  }

  &.vacancy {
    margin-bottom: 80px;

    @include min768 {
      margin-bottom: 72px;
    }
    @include min1280 {
      margin-bottom: 112px;
    }
  }
}

.wrap {
  position: relative;
}

.back {
  margin-bottom: 24px;

  @include min768 {
    display: flex;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.inner {
  width: 100%;
  box-sizing: border-box;
  
  @include min768 {
    padding-left: 91px;
    padding-right: 91px;
  }
  @include min1280 {
    padding-left: 228px;
    padding-right: 228px;
  }
}

.labels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.label {
  margin-right: 8px;
  margin-bottom: 8px;
}

.title {
  color: $orange;
  composes: h2 from global;
}

.additional {
  width: 100%;
  margin-top: 24px;

  @include min768 {
    display: flex;
    align-items: flex-end;
    margin-top: 32px;
  }
  @include min1280 {
    margin-top: 64px;
  }
}

.sign {
  margin-bottom: 48px;

  @include min768 {
    margin-bottom: 0;
    order: 2;
    margin-left: auto;
  }

  p {
    font-family: $jeff;
    font-size: 29px;
    line-height: 1;
    letter-spacing: -0.01em;
    max-width: 310px;

    @include min768 {
      max-width: 310px;
      letter-spacing: -0.02em;
    }
    @include min1280 {
      max-width: 450px;
      font-size: 40px;
    }
    @include min1024 {
      padding-left: 1rem;
      padding-bottom: 1rem;
      margin-left: -1rem;
      margin-bottom: -1rem;
    }
  }
}

.button {
  align-self: flex-start;
  margin-top: 24px;
  
  @include min768 {
    margin-top: 32px;
  }
  @include min1280 {
    margin-top: 40px;
  }
}

.share {

  @include min768 {
    order: 1;
  }
}

.sharetitle {
  composes: small-links from global;
  text-transform: uppercase;
  margin-bottom: 8px;
  @include min1280 {
    margin-bottom: 16px;
  }
}
