@import 'STYLES/variables';

.main {
  overflow: hidden;

  @include min768 {
    padding-bottom: 128px;
    margin-bottom: -128px;
  }
  @include min1280 {
    padding-bottom: 140px;
    margin-bottom: -140px;
  }
}

.wrap {
  padding-top: 128px;

  @include min768 {
    padding-top: 160px;
  }
  @include min1280 {
    padding-top: 168px;
  }
}

.heading {
  margin-bottom: 48px;
  text-align: center;
  color: $orange;
}

.game-block {
  display: flex;
  flex-direction: column;
  text-align: center;

  @include min768 {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;

    .img-wrap {
      display: block;
      opacity: 0;
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
    }
  }

  &:nth-of-type(odd) {
    @include min768 {
      .img-wrap:hover {
        transform: rotate(6deg);
      }
    }
  }

  &:nth-of-type(even) {
    @include min768 {
      .img-wrap:hover {
        transform: rotate(-6deg);
      }
    }
  }

  &.visible {
    @include min768 {
      .image {
        width: 100%;
      }
      .img-wrap {
        opacity: 1;
      }
    }
  }
}

.game-block + .game-block {
  margin-top: 80px;

  @include min768 {
    margin-top: 120px;
  }
}

.image {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform, width;

  @include min768 {
    width: 70%;
    transition: transform 0.7s cubic-bezier(0.6, 0.25, 0.36, 0.98), width 0.4s $ease 0.3s;
  }

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 8px solid $white;
    border-radius: 104px;
    z-index: 1;

    @include min768 {
      border-width: 12px;
    }
    @include min1280 {
      border-radius: 200px;
      border-width: 16px;
    }
  }


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 104px;
    position: relative;
    z-index: 0;

    @include min1280 {
      border-radius: 200px;
    }
  }
}

.safari {
  @include min768 {
    .image {
      left: 0;
    }

    &:nth-of-type(odd) .image {
      transform: rotate(8deg) translate3d(0, 70%, 0.002px);
    }

    &:nth-of-type(even) .image {
      transform: rotate(-8deg) translate3d(0, 70%, 0.002px);
    }

    &.visible .image {
      transform: rotate(0deg) translate3d(0, 0, 0.002px);
    }
  }
}

.not-safari {
  @include min768 {
    .image {
      left: 50%;
    }

    &:nth-of-type(odd) .image {
      transform: rotate(8deg) translate3d(-50%, 70%, 0.002px);
    }

    &:nth-of-type(even) .image {
      transform: rotate(-8deg) translate3d(-50%, 70%, 0.002px);
    }

    &.visible .image {
      transform: rotate(0deg) translate3d(-50%, 0, 0.002px);
    }
  }
}

.img-wrap {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 32px;
  padding-bottom: 50%;
  transition: transform 0.6s $ease,  opacity 0.4s $ease;

  @include min768 {
    margin-bottom: 0;
    width: 50%;
  }
}

.info {
  h4 {
    color: $orange;
    margin-bottom: 8px;

    @include min1280 {
      margin-bottom: 16px;
    }
  }

  p {
    margin-bottom: 1.7rem;
  }

  @include min768 {
    width: calc(100% / 12 * 4);
    margin: 40px auto 0;
    box-sizing: border-box;
  }
}
