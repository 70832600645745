@import '/styles/variables';


.main {
  overflow: hidden;
  padding-top: 128px;
  
  @include min768 {
    padding-top: 160px;
  }
  @include min1280 {
    padding-top: 168px;
  }
}


.title {
  color: $orange;
  text-align: center;
  margin-bottom: 48px;
  
  @include min768 {
    margin-bottom: 64px;
  }
  @include min1280 {
    margin-bottom: 80px;
  }
}


.slider {
  width: 100%;
  
  @include min768 {
    max-width: calc(100% / 8 * 6);
  }
  @include min1280 {
    max-width: calc(100% / 12 * 10);
  }
}
