@import '../../../../../styles/variables';

.drop {

}

.wrap {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 24px;

  a {
    font-size: 18px;
    line-height: 1.5em;
    font-weight: 500;
    font-family: $hagrid;
    display: block;

    & + a {
      margin-top: 8px;
    }

    @include min768 {
      font-size: 24px;
    }

  }
}
