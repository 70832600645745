@import 'styles/variables';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;


  .title, p {
    text-align: center;
    color: $orange;

    @include min768 {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  p {
    margin-top: 16px;
  }
}

.reload {
  margin-top: 24px;

  @include min768 {
    margin-top: 48px;
  }
  @include min1280 {
    margin-top: 64px;
  }
}
