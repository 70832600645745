@import '/styles/variables';

.block {
  box-sizing: border-box;
  aspect-ratio: 1/0.5625;
  max-height: 90vh;
  max-width: 100%;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: 24px;
  overflow: hidden;
  transform: translateZ(0.002px);
  
  @include min768 {
    border-radius: 32px;
  }
  @include min1280 {
    border-radius: 48px;
  }
  
  * {
    box-sizing: border-box;
  }
}

.youtube {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}


.cross {
  position: fixed;
  top: 7px;
  right: 7px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.85;
  }
  @include min768 {
    top: 16px;
    right: 16px;
  }
  @include min1280 {
    top: 32px;
    right: 32px;
  }
 

  svg {
    display: block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
}
