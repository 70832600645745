@import '/styles/variables';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: $white;
  color: $black;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

///карточка STUFF
.stuff {
  box-sizing: border-box;
  padding: 8px 8px 24px;
  overflow: hidden;
  border-radius: 64px;

  @include min1280 {
    padding: 16px 16px 40px;
    border-radius: 120px;
  }

  &:hover {
    img:nth-of-type(2) {
      opacity: 1;
    }
  }

  .title + p {
    margin-top: 2px;

    @include min1280 {
      margin-top: 8px;
    }
  }
}

.image-wrap {
  position: relative;
  border-radius: 56px;
  overflow: hidden;
  margin-bottom: 24px;
  width: 100%;
  aspect-ratio: 1 / 0.56;
  transform: translate3d(0, 0, 0.3px);

  @include min1280 {
    border-radius: 104px;
  }

  img {
    &:nth-of-type(1) {
      position: relative;
      z-index: 1;
    }

    &:nth-of-type(2) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s $ease;
    }
  }
}


///карточка SCREEEEEENS
.screens {
  aspect-ratio: 1 / 0.68;
  border: 8px solid $white;
  border-radius: 48px;
  overflow: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;

  @include min768 {
    border-radius: 64px;
    border-width: 12px;
  }
  @include min1280 {
    border-radius: 80px;
    //border-width: 16px;
  }
}


///карточка GAMES
.games {
  position: relative;

  &::after {
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background: $orange;
    content: '';
    position: absolute;
    border-radius: 64px;

    @include min1280 {
      border-radius: 120px;
    }
  }

  &:hover {
    .games-wrap {
      transform: translate3d(-12px, -12px, 0);

      &::before {
        opacity: 1;
      }
    }
  }
}

.games-wrap {
  border-radius: 64px;
  padding: 8px 8px 24px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  transition: transform 0.4s $ease;
  transform: translate3d(0, 0, 0);
  height: 100%;
  box-sizing: border-box;

  @include min1280 {
    padding: 16px 16px 40px;
    border-radius: 120px;
  }

  .title + p {
    margin-top: 8px;

    @include min1280 {
      margin-top: 16px;
    }
  }

  .content {
    max-width: 380px;
    padding-left: 16px;
    padding-right: 16px;

    @include min768 {
      padding: 0;
    }
  }


  &::before {
    border: 1px solid $orange;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    content: '';
    position: absolute;
    border-radius: 64px;
    transition: opacity 0.2s linear;
    opacity: 0;

    @include min1280 {
      border-radius: 120px;
    }
  }
}

.games-image {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1 / 0.67;
  border-radius: 56px;
  margin-bottom: 24px;
  width: 100%;
  backface-visibility: hidden;

  @include min768 {
    @media (max-height: 600px) {
      aspect-ratio: 1 / 0.52;
    }
  }
  @include min1280 {
    border-radius: 104px;
    margin-bottom: 40px;
    aspect-ratio: 1 / 0.52;
  }
}
