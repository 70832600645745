@import '../../../../../../styles/variables';

.block {
  margin-bottom: 56px;
  composes: article-wrapper from global;
  //cursor: none;

  @include min768 {
    margin-bottom: 80px;
  }
  @include min1280 {
    margin-bottom: 120px;
  }
}

.wrap {
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  transition: border-radius 0.3s ease;

  @include rect(56.25%);
  @include min768 {
    border-radius: 80px;
  }

  .preview,
  .play {
    pointer-events: none;
  }
}

.preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: opacity 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgb(18 18 18 / 30%), rgb(18 18 18 / 30%));
    z-index: 2;
  }
}

.picture {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  object-fit: cover;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.play-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;


  &:hover .play {
    background-color: transparentize($orange, 0.15);
  }
}

.play {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 4px solid $white;
  background-color: $orange;
  border-radius: 50%;
  transition: background-color 0.3s $ease;
  pointer-events: none;
  cursor: pointer;

  @include center-center;
  @include min768 {
    width: 96px;
    height: 96px;
    pointer-events: auto;
  }
  @include min1280 {
    width: 120px;
    height: 120px;
  }

  span {
    font-family: $ibm;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $white;

    @include min1280 {
      font-size: 18px;
    }
  }
}

.mobile {
  video {
    width: 100%;
    height: 100%;
    display: block;
  }

  .preview {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    border-radius: 40px;
    overflow: hidden;

    @include min768 {
      border-radius: 80px;
    }
  }

  &.triggered {
    display: block;

    .preview {
      display: none;
    }

    .play {
      display: none;
    }
  }
}

.mobilevideo {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  iframe,
  video {
    border-radius: 40px;
    overflow: hidden;

    @include min768 {
      border-radius: 80px;
    }
  }
}

.youtube {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 40px;
  overflow: hidden;
  z-index: 1;


  &.triggered {
    iframe {
      // pointer-events: auto;
    }
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // pointer-events: none;
  }
}
