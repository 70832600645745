@import '/styles/variables';

.main {
  padding: 128px 0 0;
 
  @include min768 {
    padding: 160px 0 0;
  }
  @include min1280 {
    padding: 168px 0 0;
  }
}

.title {
  text-align: center;
  color: $orange;
  margin-bottom: 48px;

  @include min768 {
    margin-bottom: 64px;
  }
  @include min1280 {
    margin-bottom: 80px;
  }
}


.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @include min768 {
    a {

      &:nth-of-type(3n),
      &:nth-of-type(3n + 2) {
        width: calc(50% - 4px);
      }

      &:nth-of-type(6n + 4) {
        & > span {
          flex-direction: row;
        }
      }
      &:nth-of-type(6n + 1) {
        & > span {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

