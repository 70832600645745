@import '/styles/variables';

//mobile

.select {
  padding: 12px 44px 12px 24px;
  border: 1px solid $orange;
  backface-visibility: hidden;
  color: $orange;
  border-radius: 48px;
  font: 400 16px/1 $ibm;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  position: relative;
  background-image: url('./img/icon-down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  background-size: 10px auto;
  background-color: $orange10;
  appearance: none;

  @include min1280 {
    font-size: 18px;
    padding: 20px 52px 20px 32px;
  }

  &::-ms-expand {
    display: none;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}


/// desktop
.drop {
  width: 100%;
  color: $orange;
  border-radius: 40px;
  align-items: flex-start;
  position: relative;
  z-index: 4;

  button,
  a {
    font: 400 16px/1 $ibm;

    @include min1280 {
      font-size: 18px;
    }
  }

  &:hover {
    .body {
      max-height: 390px;
      background: $white;
      transition-duration: 0.6s;

      &::before,
      &::after {
        opacity: 1;
        transition-duration: 0.3s;
        transition-delay: 0.2s;
      }
    }

    .icon {
      transform: rotate(180deg) translate3d(0, 50%, 0.1px);
    }
  }
}

.head {
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  padding: 16px 44px 16px 24px;

  @include min1280 {
    padding: 23px 52px 23px 32px;
  }
}

.icon {
  fill: $orange;
  position: absolute;
  top: 50%;
  right: 24px;
  transform-origin: center;
  transform: translate3d(0, -50%, 0.1px);
  transition: transform 0.3s $ease;
  width: 12px;
  height: 6px;

  @include min1280 {
    width: 16px;
    right: 28px;
    height: 8px;
  }
}

.body {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  transition: max-height 0.3s $ease;
  top: 0;
  z-index: -1;
  border: 1px solid $orange;
  background: $orange10;
  max-height: 46px;
  padding-top: 46px;
  border-radius: 32px;

  @include min768 {

  }
  @include min1280 {
    max-height: 64px;
    padding-top: 64px;
    border-radius: 40px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
  }

  &::before {
    z-index: -1;
    background: $white;
    top: 0;
    bottom: 0;
    transition: opacity 0.1s $ease;
  }

  &::after {
    top: 46px;
    height: 1px;
    background: $orange30;
    transition: opacity 0.1s $ease;

    @include min1280 {
      top: 64px;
    }
  }
}

.wrap {
  padding-top: 8px;
  padding-bottom: 20px;
  height: 100%;
  max-height: 320px;
  box-sizing: border-box;
  overflow: auto;
}

.active {
  pointer-events: none;

  .selected {
    opacity: 1;
  }
}

.item {
  box-sizing: border-box;
  display: block;
  transition: background-color 0.3s $ease;
  padding: 6px 24px;
  width: 100%;
  text-align: left;

  @include min1280 {
    padding: 8px 32px;
  }

  &:hover {
    background: $orange30;
  }
}

.selected {
  display: inline-block;
  margin-left: 8px;
  position: relative;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  width: 3px;
  height: 7px;
  transform: rotate(45deg);
  bottom: 2px;
  transition: opacity 0.3s $ease;
  opacity: 0;
}
