@import '/styles/variables';

.sticker {
  &.left {
    .sign {
      right: 0;
    }
  }

  &.right {
    .sign {
      left: 0;
    }
  }

  &.orange {
    .sign {
      background-color: $orange;
    }
  }

  &.purple {
    .sign {
      background-color: $purple;
    }
  }

  &.yellow {
    .sign {
      background-color: $yellow;
    }
  }

  &.black {
    .sign {
      background-color: $black;
    }
  }

  &.green {
    .sign {
      background-color: $green;
    }
  }

  &.lithuania {
    .sign {
      right: 5px;
    }
  }

  &.poland {
    .sign {
      bottom: -12px;

      @include min1280 {
        bottom: -16px;
        left: 8px;
      }
    }
  }

  &.ukraine {
    .sign {
      bottom: -12px;

      @include min1280 {
        bottom: -20px;
      }
    }
  }

  &.montenegro {
    .sign {
      left: 55px;
      bottom: 8px;

      @include min1280 {
        bottom: -5px;
        left: -72px;
      }
    }
  }

  &.georgia {
    .sign {
      bottom: -22px;
      right: 12px;

      @include min1280 {
        bottom: -30px;
        right: 20px;
      }
    }
  }

  &.cyprus {
    .sign {
      bottom: 0;
      left: 48px;

      @include min1280 {
        bottom: 0;
        left: 70px;
      }
    }
  }

  &:global(.active) {
    .inner {
      opacity: 1;
    }

    .sign {
      transform: scaleX(1);

      span {
        opacity: 1;
        transition: opacity 0.3s ease 0.3s;
      }
    }
  }
}

.sign {
  position: absolute;
  bottom: 0;
  background-color: $orange;
  padding: 3px 10px 3px;
  border-radius: 10px;
  transition: transform 0.3s ease 0s;
  transform-origin: 50% 0;

  @include min1280 {
    padding: 4px 16px 4px;
    border-radius: 16px;
    transform: scaleY(0);
  }

  span {
    color: $white;
    font-family: $hagrid;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
    text-transform: capitalize;

    @include min1280 {
      font-size: 24px;
      opacity: 0;
      transition: opacity 0.3s ease 0s;
    }
  }
}


.icon {
  position: relative;
  z-index: 1;
}

.outer {

}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include min1280 {
    transition: opacity 0.3s ease;
    opacity: 0;
  }
}
