@import '../../../../styles/variables';

.main {
  padding-top: 80px;
  overflow: hidden;
  
  @include min768 {
    padding-top: 120px;
  }
  @include min1280 {
    padding-top: 180px;
    padding-bottom: 64px;
    margin-bottom: -64px;
  }
  @include min1600 {
    padding-top: 220px;
  }
}

.wrap {
  img {
    border: 8px solid #FFF;
    border-radius: 48px;
    height: auto;
    object-fit: cover;
    box-sizing: border-box;
    
    @include min768 {
      border-width: 12px;
      border-radius: 64px;
    }
    @include min1280 {
      border-radius: 120px;
      border-width: 16px;
    }
  }
}

.slider-wrap {
  position: relative;
  transform: translateZ(0.02px);
  
  @include pointsTablet {
    width: calc(var(--col) * 6);
    margin-right: auto;
    margin-left: auto;
  }
}

.slider {
  overflow: visible !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  
  @include min1280 {
    padding-bottom: 32px;
  }
  
  [class*='swiper-slide'] {
    height: auto;
    //width: 100%;
  }
  
  [class*='swiper-wrapper'] {
    @include max768 {
      flex-direction: column;
      gap: 48px;
      transform: translate3d(0, 0, 0) !important;
    }
    @include max1280 {
      margin-bottom: 32px;
    }
  }
  
  [class*='swiper-pagination'] {
    width: 355px;
    z-index: 3;
    height: 2px;
    position: relative;
    display: none;
    
    @include min768 {
      display: block;
    }
    @include min1280 {
      width: 220px;
      right: 0;
      bottom: 0;
      position: absolute;
    }
    
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: $orange;
      opacity: 0.3;
    }
    
    & > span {
      width: 100%;
      height: 100%;
      background: $orange;
      transform-origin: 0 0;
      display: block;
      
      &::after {
        display: none;
      }
    }
  }
}

.slide {
  width: 100%;
  
  @include min768 {
    @include max1024 {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }
  
  @include min1024 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //gap: 124px;
  }
}

.title-wrap {
  margin-bottom: 32px;
  
  h1, h2, h3 {
    color: $orange;
  }
  
  @include pointsTablet {
    text-align: center;
    margin-bottom: 48px;
  }
  
  @include max768 {
    h2 {
      margin-bottom: 16px;
    }
  }
}


.text {
  box-sizing: border-box;
  margin-bottom: 32px;
  width: 100%;
  
  @include min768 {
    margin-bottom: 0;
    max-width: 600px;
    margin-top: 24px;
  }
  @include min1024 {
    width: calc(100% / 12 * 4);
  }
  @include min1280 {
    margin-bottom: 32px;
    width: calc(100% / 12 * 3);
    //padding-right: var(--col);
    //max-width: calc(var(--col) * 7);
  }
  
  h2,
  h3 {
    color: $orange;
  }
  h2 {
    @include min1024 {
      //  margin-bottom: 48px;
    }
    @include min1280 {
      margin-bottom: 48px;
    }
  }
  h3, h4 {
    @include h4;
  }
  
  p {
    margin-top: 16px;
    
    @include min768 {
      margin-top: 24px;
    }
    @include min1024 {
      margin-top: 32px;
      max-width: 300px;
    }
    
  }
  
  p + p {
    @include min768 {
      margin-top: 16px;
    }
    @include min1280 {
      margin-top: 24px;
    }
  }
}


.image {
  display: flex;
  //align-items: center;
  box-sizing: border-box;
  //margin-bottom: 56px;
  
  @include min768 {
    margin-bottom: 0;
  }
  @include min1024 {
    width: calc(100% / 12 * 7);
  }
  @include min1280 {
    width: calc(100% / 12 * 8);
    //padding-right: var(--col);
  }
  
  &.xl {
    img {
      margin-left: auto;
      aspect-ratio: 1 / 0.63;
      width: 100%;
      
      @include min768 {
        width: calc(100% - 24px);
        
        @include max1024 {
          max-width: 700px;
        }
      }
    }
  }
  &.xs {
    display: flex;
    flex-flow: nowrap row;
    gap: 8px;
    
    @include min480 {
      gap: 16px;
    }
    
    @include max768 {
      padding-bottom: 32px;
    }
    
    @include min768 {
      @include max1024 {
        height: 100%;
      }
    }
    
    img {
      aspect-ratio: 1 / 1.38;
      width: calc(100% / 3 - 6px);
      border-radius: 32px;
      
      @include min480 {
        width: calc(100% / 3 - 12px);
        border-radius: 48px;
      }
      
      @include min1024 {
        border-radius: 56px;
        border-width: 12px;
      }
      @include min1280 {
        border-radius: 80px;
        border-width: 16px;
      }
      
      &:nth-of-type(1) {
        align-self: self-start;
        
        @include min1024 {
          transform: translate3d(0, -24px, 0);
        }
      }
      
      &:nth-of-type(2) {
        align-self: self-end;
        
        @include max768 {
          transform: translate3d(0, 32px, 0);
        }
      }
      &:nth-of-type(3) {
        align-self: center;
        
        @include max768 {
          transform: translate3d(0, -16px, 0);
        }
      }
    }
    /*
    @include min768 {
      gap: 16px;
      justify-content: center;
      width: calc(var(--col) * 4);
    }
    @include min1280 {
      width: calc(var(--col) * 7);
    }
     */
  }
}



.nav {
  display: none;
  
  @include min1280 {
    display: flex;
    flex-direction: row;
    gap: 8px;
    z-index: 2;
  }
}

.next svg {
  transform: rotate(180deg);
}

.button {
  z-index: 10;
  
  span {
    display: block;
    position: relative;
    //position: absolute;
    bottom: 18px;
    left: 0;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 50vw;
    height: calc(100% - 110px);
  }
  
  &.prev::before {
    //background: rgba(255 0 0 /20%);
    background: transparent;
    left: 50%;
    transform: translate3d(-50vw, 0, 0);
  }
  
  &.next::before {
    //background: rgba(0 255 0 /20%);
    background: transparent;
    right: 50%;
    transform: translate3d(50vw, 0, 0);
  }
}
