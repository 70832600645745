@import '../../../../styles/variables';


.main {
  padding-top: 128px;

  @include min768 {
    padding-top: 160px;
  }

  @include min1280 {
    padding-top: 168px;
  }
}

.heading {
  margin-bottom: 48px;
  text-align: center;
  color: $orange;
  
  @include min768 {
    margin-bottom: 64px;
  }
  @include min1280 {
    margin-bottom: 80px;
  }
}

.image {
  width: 100%;
  object-fit: cover;
  height: 376px;
  border: 8px solid $white;
  border-radius: 104px;
  box-sizing: border-box;
  margin-bottom: 32px;
  
  @include min768 {
    display: block;
    width: 100%;
    height: 360px;
    border-width: 12px;
    //height: auto;
  }
  @include min1280 {
    margin-bottom: 48px;
    height: 640px;
    border-width: 16px;
  }
}

.description {
  font-family: $jeff;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  color: #121212;

  @include min768 {
    max-width: 305px;
  }
  @include min1280 {
    max-width: 448px;
  }
  @include min1024 {
    padding-left: 1rem;
    margin-left: -1rem;
    padding-bottom: 1rem;
    margin-bottom: -1rem;
  }
}

.button {
  margin-top: 24px;
}

.info{
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;


  .social {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &Title {
      margin-bottom: 8px;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 140%;
      letter-spacing: 0.06em;

      @include min1280 {
        font-size: 12px;
      }
    }

    @include min768 {
      margin-left: calc(100% /12);
    }

    @include min1280 {
      margin-left: calc(100% /12 * 2);
    }

  }

  .about {
    margin-bottom: 48px;

    @include min768 {
      margin-bottom: 0;
    }
  }

  @include min768 {
    flex-direction: row;
    justify-content: left;

    .social {
      width: 45%;
    }

    .about {
      width: 45%;
    }
  }
}
