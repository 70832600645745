@import '/styles/variables';


.main {
  overflow: hidden;
  margin-top: 80px;

  @include min768 {
    margin-top: 120px;
  }
  @include min1280 {
    margin-top: 168px;
  }
}

.slider {
  width: calc(100% - 80px);

  :global(.swiper-wrapper) {
    padding-top: 24px;

    @include min768 {
      padding-top: 32px;
    }

    :global(.swiper-slide) {
      transform: scale(0.9);
      transition: transform 0.6s $ease;

      & > * {
        margin: 0 auto;
        max-width: calc(100% - 8px) !important;

        @include min768 {
          max-width: calc(100% - 24px) !important;
        }
      }
    }

    :global(.swiper-slide-active), :global(.swiper-slide-duplicate-active) {
      transform: scale(1.1);
    }
  }

  @include min768 {
    width: 100%;
    max-width: 680px;
    
    @media(max-height: 600px) {
      max-width: 500px;
    }
  }
}
