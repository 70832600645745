@import '../../../../styles/variables';

.columns {
  gap: 8px;
  display: flex;
  flex-direction: column;

  @include min768 {
    gap: 0 32px;
    flex-direction: row;
  }
  @include pointsTablet {
    flex-wrap: wrap;
  }
  @include min1280 {
    justify-content: space-between;
  }
}

.col {
  width: 100%;
}

.columns2 {
  @media (min-width: 768px) and (max-width: 1024px) {
    .col + .col {
      margin-top: 16px;
    }
  }
  @media (min-width: 1024px) {
    gap: 32px;

    .col {
      width: calc(50% - 16px);
    }
  }

  @include min1440 {
    gap: 56px;

    .col {
      width: calc(50% - 28px);
    }
  }
}

.columns3 {
  @media (min-width: 768px) and (max-width: 1023px) {
    .col:nth-of-type(2) {
      margin-top: 16px;
    }
  }
  @media (min-width: 1024px) {
    .col:nth-of-type(1),
    .col:nth-of-type(2) {
      width: calc(50% - 28px);
    }
  }

  @include min1280 {
    //gap: 56px;

    .col:nth-of-type(1),
    .col:nth-of-type(2),
    .col:nth-of-type(3) {
      width: calc(33.3% - 36px);
    }

    .col:nth-of-type(3) {
      //padding-left: 56px;
      position: relative;
      top: -4px;
      width: 350px;

      div {
        max-width: 270px;
        margin-left: auto;
      }
    }
  }
}

.subtitle {
  color: $orange;
  margin-bottom: 16px;

  @include min1280 {
    max-width: 330px;
    margin-bottom: 24px;
  }
}


.buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  @include min768 {
    //margin-top: 48px;
  }
  @include min1280 {
    //width: calc(65% - 19px);
    width: 100%;
  }
}

.small-btn {
  @include min1280 {
    max-width: calc(100% / 3 * 2 - 18px);
    margin-top: -42px;
  }
}

.button-smaller {
  @include min1280 {
    //width: calc(60% - 19px);
  }
}

.button {
  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  span {
    width: 100%;
  }
}

.agreement {
  text-align: center;
  margin-top: 8px;
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
  font-family: $ibm;
  color: $orange;

  @include min768 {
    font-size: 14px;
    text-align: left;
    margin-left: 32px;
  }
  @include min1280 {
  }

  a {
    white-space: nowrap;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}


////////
.popup {

}

.form-items {
  width: 100%;
}

.with-image {
  @include min1280 {
    display: flex;
    flex-flow: row nowrap;
    gap: 64px;
  }

  .form-items {
    @include min1280 {
      width: calc(100% - 350px);
    }
  }
}

.no-image {

}

.image {
  display: none;

  @include min1280 {
    display: block;
    width: 350px;

    video,
    img {
      width: 100%;
      height: auto;
      margin-left: auto;
      display: block;
      position: relative;
      top: -6px;
      max-width: 242px;
    }
  }
}
