@import '/styles/variables';


.cookie {
  position: fixed;
  z-index: 10;
  left: 16px;
  bottom: 16px;
  display: flex;
  display: none;

  @include min768 {
    left: auto;
    right: 24px;
    bottom: 24px;
  }
  @include min1280 {
    right: 40px;
    bottom: 40px;
  }

  @media (min-width: 1760px) {
    right: calc(50vw - 850px);
  }
}

.block {
  background: $white;
  border: 1px solid $orange;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 8px 8px 8px 16px;

  @include min768 {
    border-radius: 24px;
    padding: 16px 16px 16px 24px;
    margin-left: auto;
  }
  @include min1280 {
    padding: 24px 24px 24px 32px;
    border-radius: 32px;
    gap: 64px;
  }
}

.btn {
  z-index: 2;
}
