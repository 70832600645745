@import '../../../../../../styles/variables';

.block {
  composes: article-wrapper from global;
  margin-top: 56px;
  margin-bottom: 48px;

  @include min768 {
    margin-top: 64px;
    margin-bottom: 80px;
  }
  @include min1280 {
    margin-top: 80px;
    margin-bottom: 120px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.picture {
  border-radius: 40px;
  overflow: hidden;

  @include min768 {
    border-radius: 80px;
  }

  img {
    max-width: 100%;
    display: block;
  }
}

.sign {
  text-align: center;
  font-family: $ibm;
  font-size: 12px;
  line-height: 1.4em;
  margin-top: 8px;

  @include min768 {
    font-size: 16px;
    margin-top: 16px;
  }
  @include min1280 {
    font-size: 18px;

  }
}
