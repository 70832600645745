@import '../../../../styles/variables';

.social {
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}